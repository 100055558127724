import React, { useState } from "react"
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
// import Car from "../icons/car"
// import Calendar from "../icons/calendar"
import Chevron from "../icons/chevron"


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SearchBarProduct(props) {

    const [vehicle, setVehicle] = useState(null)
    const [carYears, setCarYears] = useState(null)
    const windowGlobal = typeof window !== 'undefined' && window

    var [selectedMakeName, setSelectedMakeName] = React.useState("")
    var [selectedYear, setSelectedYear] = React.useState("")

    const vehicleSelected = (vehicleMake) => {
        setVehicle(vehicleMake)

        if (vehicleMake === "" || vehicleMake === null || vehicleMake === 'Select Vehicle') {
            setSelectedMakeName("")
            windowGlobal.localStorage.setItem("vehicleMake", '')
            windowGlobal.localStorage.setItem("vehicleYear", '')
            window.location.reload()
            return
        }
        setSelectedMakeName(vehicleMake)
        windowGlobal.localStorage.setItem("vehicleMake", vehicleMake)
        window.location.reload()
    }

    const yearSelected = (vehicleYear) => {
        setCarYears(vehicleYear)

        if (vehicleYear === "" || vehicleYear === null || vehicleYear === 'Year') {
            setSelectedYear("")
            windowGlobal.localStorage.setItem("vehicleMake", '')
            windowGlobal.localStorage.setItem("vehicleYear", '')
            window.location.reload()
            return
        }
        setSelectedYear(vehicleYear)
        windowGlobal.localStorage.setItem("vehicleYear", vehicleYear)
        window.location.reload()
    }

    let startYear = 2000
    let currentYear = new Date().getFullYear()
    const vehicleYears = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index)
    vehicleYears.unshift("Year")

    const vehicleMakes = [
        { id: 0, make: "Select Vehicle" },
        { id: 1, make: "Acura" },
        { id: 2, make: "Alfa Romeo" },
        { id: 3, make: "Aston Martin" },
        { id: 4, make: "Audi" },
        { id: 5, make: "Bentley" },
        { id: 6, make: "BMW" },
        { id: 7, make: "Buick" },
        { id: 8, make: "Cadillac" },
        { id: 9, make: "Chevrolet" },
        { id: 10, make: "Chrysler" },
        { id: 11, make: "Dodge" },
        { id: 12, make: "Ferrari" },
        { id: 13, make: "Fiat" },
        { id: 14, make: "Ford" },
        { id: 15, make: "GMC" },
        { id: 16, make: "Honda" },
        { id: 17, make: "Hyundai" },
        { id: 18, make: "Infiniti" },
        { id: 19, make: "Jaguar" },
        { id: 20, make: "Jeep" },
        { id: 21, make: "Kia" },
        { id: 22, make: "Lamborghini" },
        { id: 23, make: "Land Rover" },
        { id: 24, make: "Lexus" },
        { id: 25, make: "Maserati" },
        { id: 26, make: "Mazda" },
        { id: 27, make: "Mclaren" },
        { id: 28, make: "Mercedes Benz" },
        { id: 29, make: "Mini Cooper" },
        { id: 30, make: "Mitsubishi" },
        { id: 31, make: "Nissan" },
        { id: 32, make: "Porsche" },
        { id: 33, make: "Rolls Royce" },
        { id: 34, make: "Scion" },
        { id: 35, make: "Subaru" },
        { id: 36, make: "Tesla" },
        { id: 37, make: "Toyota" },
        { id: 38, make: "Volkswagen" }
    ]

    return (
        <div className="bg-newlndgray mt-5 py-5 md:w-[450px] rounded-xl space-x-5 pr-5 lg:pr-0">
            <p className="font-bold px-5 pb-5 text-left">Your Vehicle</p>
            <div className="flex space-x-5">
                <div className="border border-gray-600 rounded-xl bg-white">
                    <Menu as="div" className="relative inline-block text-left md:w-48">
                        <div>
                            <Menu.Button className="inline-flex justify-center gap-x-5 md:gap-x-12 px-3 py-4 text-xs xl:text-sm font-semibold">
                                {(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake")) || 'Select Vehicle'}
                                <Chevron />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >

                            <Menu.Items className="absolute left-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {vehicleMakes.map(vehicle => (
                                    <Menu.Item key={`${vehicle.make}`}>
                                        {({ active }) => (
                                            <button
                                                className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                onClick={() => {
                                                    vehicleSelected(vehicle.make)
                                                }}
                                            >
                                                {vehicle.make}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>


                <div className="mx-auto pt-1 xl:pt-0 border border-gray-600 rounded-xl bg-white">
                    <Menu as="div" className="relative inline-block text-left md:w-48">
                        <div>
                            <Menu.Button className="inline-flex justify-center gap-x-10 md:gap-x-28 rounded-md px-3 pt-3 xl:pt-4 text-xs xl:text-sm font-semibold">
                                {(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleYear")) || 'Year'}
                                <Chevron />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 mt-2 w-full origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {vehicleYears.map(years => (
                                    <Menu.Item key={`${years}`}>
                                        {({ active }) => (
                                            <button
                                                className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                onClick={() => {
                                                    yearSelected(years)
                                                }}
                                            >
                                                {years}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    )
}
