// extracted by mini-css-extract-plugin
export var addToCartStyle = "product-page-module--addToCartStyle--ec273";
export var breadcrumb = "product-page-module--breadcrumb--54bff";
export var container = "product-page-module--container--4c17b";
export var header = "product-page-module--header--39891";
export var noImagePreview = "product-page-module--noImagePreview--b24ff";
export var optionsWrapper = "product-page-module--optionsWrapper--d4772";
export var priceValue = "product-page-module--priceValue--d532c";
export var productBox = "product-page-module--productBox--f4833";
export var productDescription = "product-page-module--productDescription--0f5ce";
export var productImageList = "product-page-module--productImageList--a4c86";
export var productImageListItem = "product-page-module--productImageListItem--1091d";
export var productImageWrapper = "product-page-module--productImageWrapper--aef52";
export var scrollForMore = "product-page-module--scrollForMore--9b14a";
export var selectVariant = "product-page-module--selectVariant--a07c6";
export var visuallyHidden = "product-page-module--visuallyHidden--f2d8c";