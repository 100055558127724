import * as React from "react"

function Light(props) {
    return (
        <svg className="mt-1" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15.5V18C12 18.9319 12 19.3978 11.8478 19.7654C11.6448 20.2554 11.2554 20.6448 10.7654 20.8478C10.3978 21 9.93188 21 9 21C8.06812 21 7.60218 21 7.23463 20.8478C6.74458 20.6448 6.35523 20.2554 6.15224 19.7654C6 19.3978 6 18.9319 6 18V15.5M12 15.5C14.6489 14.3427 16.5 11.5755 16.5 8.5C16.5 4.35786 13.1421 1 9 1C4.85786 1 1.5 4.35786 1.5 8.5C1.5 11.5755 3.35114 14.3427 6 15.5M12 15.5H6" stroke="#7C91B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
export default Light
