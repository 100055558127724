import * as React from "react"

function ZoomIcon() {
    return (
      <div className="flex items-center justify-center pb-2 text-gray-400 text-xs md:text-sm">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#94A3B8" width="16" height="16" viewBox="0 0 24 24">
          <path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 
                4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z"/>
        </svg>
        <p className="pl-2">Tap image to zoom</p>
      </div>
    )
  }
export default ZoomIcon

