import React from "react"
// import { TransButton } from "../components/button-transparent"
import { Stars } from "../components/stars"
import { ProductCarousel } from '../components/product-image-carousel'
import { selectVariant } from "../pages/services/{ShopifyProduct.productType}/product-page.module.css"
import { StoreContext } from "../context/store-context.jsx"
import isEqual from "lodash.isequal"
import { AddToCart } from "../components/add-to-cart"
import { NumericInput } from "../components/numeric-input"
import { formatPrice } from "../utils/format-price"
import Popup from "./popup"
import SearchBarProduct from "../components/search-bar-product-page"
import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"
import Breadcrumb from "../components/breadcrumb"
// import { useState } from "react"
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
// import Car from "../icons/car"
// import Calendar from "../icons/calendar"
import Chevron from "../icons/chevron"
// import TypesPopup from "./popup-types"
import { BannerThreeStage } from "../components/banner-three-stage.js"
import SharePopup from "./share-popup.js"
import Info from "../icons/info.js"
import Trophy from "../icons/trophy.js"
import Light from "../icons/light.js"
import Shield from "../icons/shield.js"
import Arrow from "../icons/arrow"
import background from "../images/faq-bg.png"
import Lock from "../icons/lock"
import StopSign from "../icons/stop-sign.js"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProductMainSection = (product) => {
    var {
        // subTitle,
        title,
        priceRangeV2,
        description,
        smallDesc,
        images,
        variants,
        variants: [initialVariant],
        options,
        reviewNumber,
        isSeatbeltRepair,
        isAirbagPlugs,
        singleStageData,
        dualStageData,
        tripleStageData,
        pretensionerData,
        airbagPlugsData,
        seatBeltColorData,
        batteryCableData,
        steeringColumnData,
        srsAirBagData,
        convertibleRollBarData
    } = product

    var vehicleMake = ""
    var vehicleMakeAirbagPlugs = ""
    var isSeatBeltColor = false
    var isAirbagPlugsProduct = false
    var contentfulVariable = ""
    var isOptionalTextSingleStage = false
    var isOptionalTextDualStage = false
    var isOptionalTextTripleStage = false
    var isOptionalTextPretensioner = false
    var isOptionalTextAirBagPlugs = false
    var isOptionalTextSeatBeltColor = false
    var isOptionalTextBatteryCable = false
    var isOptionalTextSteeringColumn = false
    var isOptionalTextSRSAirbag = false
    var isOptionalTextConvertibleRollBar = false

    if (singleStageData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextSingleStage = false
    }
    else {
        isOptionalTextSingleStage = true
    }

    if (dualStageData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextDualStage = false
    }
    else {
        isOptionalTextDualStage = true
    }

    if (tripleStageData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextTripleStage = false
    }
    else {
        isOptionalTextTripleStage = true
    }

    if (pretensionerData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextPretensioner = false
    }
    else {
        isOptionalTextPretensioner = true
    }

    if (airbagPlugsData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextAirBagPlugs = false
    }
    else {
        isOptionalTextAirBagPlugs = true
    }

    if (seatBeltColorData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextSeatBeltColor = false
    }
    else {
        isOptionalTextSeatBeltColor = true
    }

    if (batteryCableData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextBatteryCable = false
    }
    else {
        isOptionalTextBatteryCable = true
    }

    if (steeringColumnData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextSteeringColumn = false
    }
    else {
        isOptionalTextSteeringColumn = true
    }

    if (srsAirBagData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextSRSAirbag = false
    }
    else {
        isOptionalTextSRSAirbag = true
    }

    if (convertibleRollBarData[0].node.optionalAdditionalText.optionalAdditionalText === 'Optional') {
        isOptionalTextConvertibleRollBar = false
    }
    else {
        isOptionalTextConvertibleRollBar = true
    }

    if (title.includes("Single Stage Seat Belt")) {
        contentfulVariable = singleStageData
    }
    else if (title.includes("Dual Stage Seat Belt")) {
        contentfulVariable = dualStageData
    }
    else if (title.includes("Triple Stage Seat Belt")) {
        contentfulVariable = tripleStageData
    }
    else if (title.includes("Pretensioner")) {
        contentfulVariable = pretensionerData
    }
    else if (title.includes("Airbag Plug")) {
        contentfulVariable = airbagPlugsData
        isAirbagPlugsProduct = true
    }
    else if (title.includes("Seat Belt Coloring")) {
        contentfulVariable = seatBeltColorData
    }
    else if (title.includes("Battery Cable")) {
        contentfulVariable = batteryCableData
    }
    else if (title.includes("Collapsible Steering Sensor")) {
        contentfulVariable = steeringColumnData
    }
    else if (title.includes("Airbag Module Reset")) {
        contentfulVariable = srsAirBagData
    }
    else if (title.includes("Convertible Roll Bar")) {
        contentfulVariable = convertibleRollBarData
    }
    else {
        contentfulVariable = ''
    }

    if (title.includes("Seat Belt Coloring")) {
        isSeatBeltColor = true
    }
    else {
        isSeatBeltColor = false
    }

    const windowGlobal = typeof window !== 'undefined' && window

    if (isSeatbeltRepair && typeof window !== 'undefined' && window.localStorage.getItem("vehicleMake")) {
        vehicleMake = window.localStorage.getItem("vehicleMake")
    }
    else if (isAirbagPlugs && typeof window !== 'undefined' && window.localStorage.getItem("vehicleMakeAirbagPlugs")) {
        vehicleMakeAirbagPlugs = window.localStorage.getItem("vehicleMakeAirbagPlugs")
    }

    const { client } = React.useContext(StoreContext)

    var [variant, setVariant] = React.useState({ ...initialVariant })
    var [quantity, setQuantity] = React.useState(1)
    var [quantitySuggested, setQuantitySuggested] = React.useState(1)
    var [quantitySuggested2, setQuantitySuggested2] = React.useState(1)
    var [quantitySuggested3, setQuantitySuggested3] = React.useState(1)
    var [IsSelected, setIsSelected] = React.useState(false)
    var [type1Selected, setType1Selected] = React.useState(false)
    var [type2Selected, setType2Selected] = React.useState(false)
    var [type3Selected, setType3Selected] = React.useState(false)
    var [type4Selected, setType4Selected] = React.useState(false)

    const productVariant = client.product.helpers.variantForOptions(product, variant) || variant

    // const handleOptionChange = (index, event) => {
    //     var value = event.target.value

    const handleOptionChange = (index, value) => {
        if (!isSeatbeltRepair) {

            if ((isSeatbeltRepair && variants.length > 1) || (isAirbagPlugs && variants.length > 1)) {

                if (index === 1) {
                    setType1Selected(true)
                    setType2Selected(false)
                    setType3Selected(false)
                    setType4Selected(false)
                } else if (index === 2) {
                    setType1Selected(false)
                    setType2Selected(true)
                    setType3Selected(false)
                    setType4Selected(false)
                } else if (index === 3) {
                    setType1Selected(false)
                    setType2Selected(false)
                    setType3Selected(true)
                    setType4Selected(false)
                } else if (index === 4) {
                    setType1Selected(false)
                    setType2Selected(false)
                    setType3Selected(false)
                    setType4Selected(true)
                }

                var selectVariant = `Type ${index}`

                const selectedVariant = variants.find((variant) => {
                    return isEqual(selectVariant, variant.title)
                })

                setIsSelected(true)
                setVariant({ ...selectedVariant })
                return
            }

            if (value === "" || value === null) {
                setIsSelected(false)
                setVariant(initialVariant)
                return
            }

            const currentOptions = [...variant.selectedOptions]

            currentOptions[index] = {
                ...currentOptions[index],
                value
            }

            const selectedVariant = variants.find((variant) => {
                return isEqual(currentOptions, variant.selectedOptions)
            })

            setIsSelected(true)
            setVariant({ ...selectedVariant })
        }
        if (!title.includes('Seat Belt Coloring')) {
            windowGlobal.localStorage.setItem("vehicleMake", value)
            windowGlobal.localStorage.setItem("vehicleYear", '')
        }
    }

    const price = formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        variant.price
    )

    const minVariantPrice = formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        priceRangeV2.minVariantPrice.amount
    )

    const maxVariantPrice = formatPrice(
        priceRangeV2.maxVariantPrice.currencyCode,
        priceRangeV2.maxVariantPrice.amount
    )

    const hasVariants = variants.length > 1
    if (!hasVariants || (isSeatbeltRepair && variants.length < 2) || (isAirbagPlugs && variants.length < 2)) {
        IsSelected = true
    }

    let seatBelt = false
    let pretensioner = false

    if (title.includes('Seat Belt Repair')) {
        seatBelt = true
    }
    if (title.includes('Pretensioner')) {
        pretensioner = true
    }

    const halfIndex = Math.ceil(description.length / 2);

    // Find the index of the last period before the halfway mark
    let splitIndex = halfIndex;
    for (let i = halfIndex; i >= 0; i--) {
        if (description[i] === '.') {
            splitIndex = i;
            break;
        }
    }

    // Split the description into two parts based on the split index
    const description1 = description.substring(0, splitIndex + 1).trim();
    const description2 = description.substring(splitIndex + 1).trim();


    if (!isSeatbeltRepair && !title.includes('Seat Belt Coloring') && !IsSelected && (windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake"))) {
        {
            setTimeout(() => {
                handleOptionChange(0, windowGlobal.localStorage.getItem("vehicleMake"));
            }, 100);
        }

    }

    const { addVariantToCart, loading } = React.useContext(StoreContext)

    function addToCart(variantId, quantity, IsSelected, hasVariants) {
        addVariantToCart(variantId, quantity)
    }

    // const matchedVariant = airbagID.find(({ car }) => car === windowGlobal.localStorage.getItem("vehicleMake"));

    const doesVehicleMakeExist = windowGlobal.localStorage ? windowGlobal.localStorage.getItem("vehicleMake") || '' : '';
    const matchedVariant = airbagID.find(({ car }) => {
        return car && car === doesVehicleMakeExist;
    }) || '';

    const matchedVariantAirbagPlugs = airbagPlugID.find(({ car }) => {
        return car && car === doesVehicleMakeExist;
    }) || '';


    let displayName = ''
    let displayNameAirbagPlugs = ''

    if (matchedVariant) {
        displayName = matchedVariant.storeFrontId;
    }

    if (matchedVariantAirbagPlugs) {
        displayNameAirbagPlugs = matchedVariantAirbagPlugs.storeFrontId;
    }

    let disabledAddButton = false
    if (((title.includes('Seat Belt Repair') && !windowGlobal || !windowGlobal.localStorage || !windowGlobal.localStorage.getItem("vehicleMake"))) || ((title.includes('Pretensioner') && !windowGlobal || !windowGlobal.localStorage || !windowGlobal.localStorage.getItem("vehicleMake")))) {
        disabledAddButton = true
    }
    else if (((!title.includes('Seat Belt Repair') && !IsSelected)) || ((!title.includes('Pretensioner') && !IsSelected))) {
        disabledAddButton = true
    }
    else {
        disabledAddButton = false
    }

    return (
        <div id='services'>
            <div className="container mx-auto">
                <div className="grid lg:grid-cols-2 flex justify-center px-5 md:px-0 xl:space-x-10 2xl:space-x-12">
                    <div>
                        <div className="pt-10 lg:pt-14 px-5 mx-auto">
                            <nav className="flex justify-center lg:justify-start container mx-auto lg:translate-x-[-20px] xl:translate-x-[-5px] 2xl:translate-x-[20px] py-3 shadow-4xl lg:pb-8" aria-label="Breadcrumb">
                                <Breadcrumb links={[
                                    { to: '/', label: 'Home' },
                                    { to: '/services', label: 'Services' },
                                    { to: product.productTypeSlug, label: product.title }
                                ]} />
                            </nav>
                            <div className="pb-2 text-center max-w-sm px-5 md:max-w-full mx-auto lg:hidden">
                                <div className="pl-12">
                                    <SharePopup url={'https://www.lndsolutions.org' + product.productTypeSlug} />
                                </div>
                                {!isSeatbeltRepair && !isAirbagPlugs && IsSelected && hasVariants ? (
                                    <h1 className="font-bold text-3xl md:text-4xl">{title} - {variant.title}</h1>
                                ) : ((
                                    <h1 className="font-bold text-3xl md:text-4xl">{(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake")) && (windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleYear")) && !title.includes('Seat Belt Coloring') ? windowGlobal.localStorage.getItem("vehicleYear") + ' ' + windowGlobal.localStorage.getItem("vehicleMake") + ' ' + title : title}</h1>
                                ))}
                            </div>
                            <div className="lg:hidden pb-2">
                                <Stars reviewNumber={reviewNumber} />
                            </div>
                            <ProductCarousel images={images} altText={variant.title} title={title + " - " + vehicleMake || vehicleMakeAirbagPlugs} isSelected={IsSelected} />

                            {title.includes('Single Stage Seat Belt') ?
                                <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                    {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                    <div className="lg:px-5 pb-5">
                                        <div className="flex gap-x-3">
                                            <Info />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{singleStageData[0].node.specificationsTitle1}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: singleStageData[0].node.specificationsBody1.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>
                                    {/* <div className="mx-auto pt-8">
                        <BannerThreeStage />
                    </div> */}
                                    <div className="border-t py-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <Trophy />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{singleStageData[0].node.specificationsTitle2}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: singleStageData[0].node.specificationsBody2.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>

                                    <div className="border-t py-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <Light />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{singleStageData[0].node.specificationsTitle3}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: singleStageData[0].node.specificationsBody3.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>

                                    <div className="border-t py-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <Shield />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{singleStageData[0].node.specificationsTitle4}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: singleStageData[0].node.specificationsBody4.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>

                                    <div className="border-t pt-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <StopSign />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{singleStageData[0].node.specificationsTitle5}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: singleStageData[0].node.specificationsBody5.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                : title.includes('Dual Stage Seat Belt') ?
                                    <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                        {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                        <div className="lg:px-5 pb-5">
                                            <div className="flex gap-x-3">
                                                <Info />
                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{dualStageData[0].node.specificationsTitle1}</h2>
                                            </div>
                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                    __html: dualStageData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                }} />
                                            </div>
                                        </div>
                                        <div className="border-t py-5">
                                            <div className="flex gap-x-3 lg:px-5">
                                                <Trophy />
                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{dualStageData[0].node.specificationsTitle2}</h2>
                                            </div>
                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                    __html: dualStageData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                }} />
                                            </div>
                                        </div>

                                        <div className="border-t py-5">
                                            <div className="flex gap-x-3 lg:px-5">
                                                <Light />
                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{dualStageData[0].node.specificationsTitle3}</h2>
                                            </div>
                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                    __html: dualStageData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                }} />
                                            </div>
                                        </div>

                                        <div className="border-t py-5">
                                            <div className="flex gap-x-3 lg:px-5">
                                                <Shield />
                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{dualStageData[0].node.specificationsTitle4}</h2>
                                            </div>
                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                    __html: dualStageData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                }} />
                                            </div>
                                        </div>

                                        <div className="border-t pt-5">
                                            <div className="flex gap-x-3 lg:px-5">
                                                <StopSign />
                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{dualStageData[0].node.specificationsTitle5}</h2>
                                            </div>
                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                    __html: dualStageData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    : title.includes('Triple Stage Seat Belt') ?
                                        <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                            {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                            <div className="lg:px-5 pb-5">
                                                <div className="flex gap-x-3">
                                                    <Info />
                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{tripleStageData[0].node.specificationsTitle1}</h2>
                                                </div>
                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                        __html: tripleStageData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="border-t py-5">
                                                <div className="flex gap-x-3 lg:px-5">
                                                    <Trophy />
                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{tripleStageData[0].node.specificationsTitle2}</h2>
                                                </div>
                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                        __html: tripleStageData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="border-t py-5">
                                                <div className="flex gap-x-3 lg:px-5">
                                                    <Light />
                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{tripleStageData[0].node.specificationsTitle3}</h2>
                                                </div>
                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                        __html: tripleStageData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="border-t py-5">
                                                <div className="flex gap-x-3 lg:px-5">
                                                    <Shield />
                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{tripleStageData[0].node.specificationsTitle4}</h2>
                                                </div>
                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                        __html: tripleStageData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="border-t pt-5">
                                                <div className="flex gap-x-3 lg:px-5">
                                                    <StopSign />
                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{tripleStageData[0].node.specificationsTitle5}</h2>
                                                </div>
                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                        __html: tripleStageData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        : title.includes('Pretensioner Repair') ?
                                            <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                <div className="lg:px-5 pb-5">
                                                    <div className="flex gap-x-3">
                                                        <Info />
                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{pretensionerData[0].node.specificationsTitle1}</h2>
                                                    </div>
                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                            __html: pretensionerData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="border-t py-5">
                                                    <div className="flex gap-x-3 lg:px-5">
                                                        <Trophy />
                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{pretensionerData[0].node.specificationsTitle2}</h2>
                                                    </div>
                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                            __html: pretensionerData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="border-t py-5">
                                                    <div className="flex gap-x-3 lg:px-5">
                                                        <Light />
                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{pretensionerData[0].node.specificationsTitle3}</h2>
                                                    </div>
                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                            __html: pretensionerData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="border-t py-5">
                                                    <div className="flex gap-x-3 lg:px-5">
                                                        <Shield />
                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{pretensionerData[0].node.specificationsTitle4}</h2>
                                                    </div>
                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                            __html: pretensionerData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="border-t pt-5">
                                                    <div className="flex gap-x-3 lg:px-5">
                                                        <StopSign />
                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{pretensionerData[0].node.specificationsTitle5}</h2>
                                                    </div>
                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                            __html: pretensionerData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            : title.includes('Seat Belt Coloring') ?
                                                <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                    {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                    <div className="lg:px-5 pb-5">
                                                        <div className="flex gap-x-3">
                                                            <Info />
                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{seatBeltColorData[0].node.specificationsTitle1}</h2>
                                                        </div>
                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                __html: seatBeltColorData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="border-t py-5">
                                                        <div className="flex gap-x-3 lg:px-5">
                                                            <Trophy />
                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{seatBeltColorData[0].node.specificationsTitle2}</h2>
                                                        </div>
                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                __html: seatBeltColorData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className="border-t py-5">
                                                        <div className="flex gap-x-3 lg:px-5">
                                                            <Light />
                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{seatBeltColorData[0].node.specificationsTitle3}</h2>
                                                        </div>
                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                __html: seatBeltColorData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className="border-t py-5">
                                                        <div className="flex gap-x-3 lg:px-5">
                                                            <Shield />
                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{seatBeltColorData[0].node.specificationsTitle4}</h2>
                                                        </div>
                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                __html: seatBeltColorData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className="border-t pt-5">
                                                        <div className="flex gap-x-3 lg:px-5">
                                                            <StopSign />
                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{seatBeltColorData[0].node.specificationsTitle5}</h2>
                                                        </div>
                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                __html: seatBeltColorData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : title.includes('Battery Cable Repair') ?
                                                    <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                        {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                        <div className="lg:px-5 pb-5">
                                                            <div className="flex gap-x-3">
                                                                <Info />
                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{batteryCableData[0].node.specificationsTitle1}</h2>
                                                            </div>
                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                    __html: batteryCableData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="border-t py-5">
                                                            <div className="flex gap-x-3 lg:px-5">
                                                                <Trophy />
                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{batteryCableData[0].node.specificationsTitle2}</h2>
                                                            </div>
                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                    __html: batteryCableData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                                }} />
                                                            </div>
                                                        </div>

                                                        <div className="border-t py-5">
                                                            <div className="flex gap-x-3 lg:px-5">
                                                                <Light />
                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{batteryCableData[0].node.specificationsTitle3}</h2>
                                                            </div>
                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                    __html: batteryCableData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                                }} />
                                                            </div>
                                                        </div>

                                                        <div className="border-t py-5">
                                                            <div className="flex gap-x-3 lg:px-5">
                                                                <Shield />
                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{batteryCableData[0].node.specificationsTitle4}</h2>
                                                            </div>
                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                    __html: batteryCableData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                                }} />
                                                            </div>
                                                        </div>

                                                        <div className="border-t pt-5">
                                                            <div className="flex gap-x-3 lg:px-5">
                                                                <StopSign />
                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{batteryCableData[0].node.specificationsTitle5}</h2>
                                                            </div>
                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                    __html: batteryCableData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : title.includes('Airbag Plug') ?
                                                        <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                            {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                            <div className="lg:px-5 pb-5">
                                                                <div className="flex gap-x-3">
                                                                    <Info />
                                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{airbagPlugsData[0].node.specificationsTitle1}</h2>
                                                                </div>
                                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                        __html: airbagPlugsData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            <div className="border-t py-5">
                                                                <div className="flex gap-x-3 lg:px-5">
                                                                    <Trophy />
                                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{airbagPlugsData[0].node.specificationsTitle2}</h2>
                                                                </div>
                                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                        __html: airbagPlugsData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                                    }} />
                                                                </div>
                                                            </div>

                                                            <div className="border-t py-5">
                                                                <div className="flex gap-x-3 lg:px-5">
                                                                    <Light />
                                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{airbagPlugsData[0].node.specificationsTitle3}</h2>
                                                                </div>
                                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                        __html: airbagPlugsData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                                    }} />
                                                                </div>
                                                            </div>

                                                            <div className="border-t py-5">
                                                                <div className="flex gap-x-3 lg:px-5">
                                                                    <Shield />
                                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{airbagPlugsData[0].node.specificationsTitle4}</h2>
                                                                </div>
                                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                        __html: airbagPlugsData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                                    }} />
                                                                </div>
                                                            </div>

                                                            <div className="border-t pt-5">
                                                                <div className="flex gap-x-3 lg:px-5">
                                                                    <StopSign />
                                                                    <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{airbagPlugsData[0].node.specificationsTitle5}</h2>
                                                                </div>
                                                                <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                    <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                        __html: airbagPlugsData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : title.includes('Collapsible Steering Sensor') ?
                                                            <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                                {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                                <div className="lg:px-5 pb-5">
                                                                    <div className="flex gap-x-3">
                                                                        <Info />
                                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{steeringColumnData[0].node.specificationsTitle1}</h2>
                                                                    </div>
                                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                            __html: steeringColumnData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="border-t py-5">
                                                                    <div className="flex gap-x-3 lg:px-5">
                                                                        <Trophy />
                                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{steeringColumnData[0].node.specificationsTitle2}</h2>
                                                                    </div>
                                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                            __html: steeringColumnData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                                        }} />
                                                                    </div>
                                                                </div>

                                                                <div className="border-t py-5">
                                                                    <div className="flex gap-x-3 lg:px-5">
                                                                        <Light />
                                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{steeringColumnData[0].node.specificationsTitle3}</h2>
                                                                    </div>
                                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                            __html: steeringColumnData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                                        }} />
                                                                    </div>
                                                                </div>

                                                                <div className="border-t py-5">
                                                                    <div className="flex gap-x-3 lg:px-5">
                                                                        <Shield />
                                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{steeringColumnData[0].node.specificationsTitle4}</h2>
                                                                    </div>
                                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                            __html: steeringColumnData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                                        }} />
                                                                    </div>
                                                                </div>

                                                                <div className="border-t pt-5">
                                                                    <div className="flex gap-x-3 lg:px-5">
                                                                        <StopSign />
                                                                        <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{steeringColumnData[0].node.specificationsTitle5}</h2>
                                                                    </div>
                                                                    <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                        <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                            __html: steeringColumnData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : title.includes('Airbag Module Reset') ?
                                                                <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                                    {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                                    <div className="lg:px-5 pb-5">
                                                                        <div className="flex gap-x-3">
                                                                            <Info />
                                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{srsAirBagData[0].node.specificationsTitle1}</h2>
                                                                        </div>
                                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                __html: srsAirBagData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="border-t py-5">
                                                                        <div className="flex gap-x-3 lg:px-5">
                                                                            <Trophy />
                                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{srsAirBagData[0].node.specificationsTitle2}</h2>
                                                                        </div>
                                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                __html: srsAirBagData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                                            }} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="border-t py-5">
                                                                        <div className="flex gap-x-3 lg:px-5">
                                                                            <Light />
                                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{srsAirBagData[0].node.specificationsTitle3}</h2>
                                                                        </div>
                                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                __html: srsAirBagData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                                            }} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="border-t py-5">
                                                                        <div className="flex gap-x-3 lg:px-5">
                                                                            <Shield />
                                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{srsAirBagData[0].node.specificationsTitle4}</h2>
                                                                        </div>
                                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                __html: srsAirBagData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                                            }} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="border-t pt-5">
                                                                        <div className="flex gap-x-3 lg:px-5">
                                                                            <StopSign />
                                                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{srsAirBagData[0].node.specificationsTitle5}</h2>
                                                                        </div>
                                                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                __html: srsAirBagData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : title.includes('Convertible Roll Bar Repair') ?
                                                                    <div className='hidden lg:block py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                                                        {/* <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2> */}
                                                                        <div className="lg:px-5 pb-5">
                                                                            <div className="flex gap-x-3">
                                                                                <Info />
                                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{convertibleRollBarData[0].node.specificationsTitle1}</h2>
                                                                            </div>
                                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                    __html: convertibleRollBarData[0].node.specificationsBody1.childMarkdownRemark.html,
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="border-t py-5">
                                                                            <div className="flex gap-x-3 lg:px-5">
                                                                                <Trophy />
                                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{convertibleRollBarData[0].node.specificationsTitle2}</h2>
                                                                            </div>
                                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                    __html: convertibleRollBarData[0].node.specificationsBody2.childMarkdownRemark.html,
                                                                                }} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-t py-5">
                                                                            <div className="flex gap-x-3 lg:px-5">
                                                                                <Light />
                                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{convertibleRollBarData[0].node.specificationsTitle3}</h2>
                                                                            </div>
                                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                    __html: convertibleRollBarData[0].node.specificationsBody3.childMarkdownRemark.html,
                                                                                }} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-t py-5">
                                                                            <div className="flex gap-x-3 lg:px-5">
                                                                                <Shield />
                                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{convertibleRollBarData[0].node.specificationsTitle4}</h2>
                                                                            </div>
                                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                    __html: convertibleRollBarData[0].node.specificationsBody4.childMarkdownRemark.html,
                                                                                }} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="border-t pt-5">
                                                                            <div className="flex gap-x-3 lg:px-5">
                                                                                <StopSign />
                                                                                <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{convertibleRollBarData[0].node.specificationsTitle5}</h2>
                                                                            </div>
                                                                            <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                                                                <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                                                    __html: convertibleRollBarData[0].node.specificationsBody5.childMarkdownRemark.html,
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                        </div>
                    </div>
                    <div>
                        <div className="lg:py-24 text-center lg:text-left px-5">
                            {/* <p className="uppercase text-lndred text-lg font-semibold">{subTitle}</p> */}
                            <div className="pb-5 hidden lg:block">
                                <div className="xl:pl-8">
                                    <SharePopup url={'https://www.lndsolutions.org' + product.productTypeSlug} />
                                </div>
                                {!isSeatbeltRepair && !isAirbagPlugs && IsSelected && hasVariants ? (
                                    <h1 className="font-bold text-3xl md:text-4xl">{title} - {variant.title}</h1>
                                ) : ((
                                    <h1 className="font-bold text-3xl md:text-4xl">{(windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleMake")) && (windowGlobal.localStorage && windowGlobal.localStorage.getItem("vehicleYear")) && !title.includes('Seat Belt Coloring') ? windowGlobal.localStorage.getItem("vehicleYear") + ' ' + windowGlobal.localStorage.getItem("vehicleMake") + ' ' + title : title}</h1>
                                ))}
                            </div>
                            <div className="hidden lg:block">
                                <Stars reviewNumber={reviewNumber} />
                            </div>
                            <div className="lg:hidden pt-2 -ml-5">
                                <Popup />
                            </div>
                            <div className="pt-5 text-lg px-5 md:px-10 max-w-sm mx-auto md:max-w-full md:mx-0 lg:px-0 text-left" dangerouslySetInnerHTML={{
                                __html: smallDesc,
                            }} />
                            <div className="font-bold text-lg pt-5 pb-2 px-5 md:px-10 lg:px-0 mx-auto max-w-sm md:max-w-full">
                                <div className="flex justify-start py-2">
                                    <Bullet />
                                    <span className="pl-2">100% OEM Parts!</span>
                                </div>
                                <div className="flex justify-start py-2">
                                    <Bullet />
                                    <span className="pl-2">Lifetime Warranty!</span>
                                </div>
                                <div className="flex justify-start py-2">
                                    <Bullet />
                                    <span className="pl-2">1 Day Turnaround!</span>
                                </div>
                                <div className="flex justify-start py-2">
                                    <Bullet />
                                    <span className="pl-2">Premium Quality Guaranteed!</span>
                                </div>
                            </div>
                            <div className="hidden lg:block">
                                {IsSelected ? (
                                    <p className="text-3xl py-3 font-bold text-lndred">{price}</p>
                                ) : (
                                    <div className="text-lndred font-bold">
                                        {minVariantPrice === maxVariantPrice ? (
                                            <p className="text-3xl py-3 font-bold">{minVariantPrice}</p>
                                        ) : (
                                            <p className="text-3xl py-3 font-bold">{minVariantPrice} - {maxVariantPrice}</p>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="lg:hidden px-12 pt-5">
                                {IsSelected ? (
                                    <p className="text-3xl py-3 font-bold text-lndred text-left">{price}</p>
                                ) : (
                                    <div className="text-lndred font-bold text-left">
                                        {minVariantPrice === maxVariantPrice ? (
                                            <p className="text-3xl py-3 font-bold">{minVariantPrice}</p>
                                        ) : (
                                            <p className="text-3xl py-3 font-bold">{minVariantPrice} - {maxVariantPrice}</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={`${(!isAirbagPlugs) ? "" : ""} mx-auto lg:mx-0`}>
                                {!seatBelt && !pretensioner ? (
                                    <div>
                                        <div className="lg:hidden flex justify-center md:justify-start items-center lg:mx-auto md:ml-10 bg-newlndgray mt-3 py-5 rounded-xl space-x-5 md:w-[370px]">
                                            <p className="font-bold px-5 pb-5 mt-5 flex items-center">{title.includes('Seat Belt Coloring') ? 'Select Color' : 'Your Vehicle'}</p>
                                            <div className="flex space-x-5">
                                                <div className="border border-gray-600 rounded-xl bg-white">
                                                    <Menu as="div" className="relative inline-block text-left md:w-48">
                                                        {title.includes('Seat Belt Coloring') ? (
                                                            <div>
                                                                <Menu.Button className="inline-flex justify-center gap-x-12 px-3 py-4 text-xs xl:text-sm font-semibold">
                                                                    {IsSelected ? variant.title : options[0].name}
                                                                    <Chevron />
                                                                </Menu.Button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <Menu.Button className="inline-flex justify-center gap-x-12 px-3 py-4 text-xs xl:text-sm font-semibold">
                                                                    {IsSelected ? variant.title : options[0].name}
                                                                    <Chevron />
                                                                </Menu.Button>
                                                            </div>
                                                        )
                                                        }
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            {isSeatBeltColor ?
                                                                <Menu.Items className="absolute right-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    {options[0].values.map((value, index) => (
                                                                        <Menu.Item key={`${index}`}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                                                    onClick={() => {
                                                                                        handleOptionChange(0, value)
                                                                                    }}
                                                                                >
                                                                                    {value}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </Menu.Items>
                                                                :
                                                                <Menu.Items className="absolute right-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    {options[0].values.slice(1).map((value, index) => (
                                                                        <Menu.Item key={`${index}`}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                                                    onClick={() => {
                                                                                        handleOptionChange(0, value)
                                                                                    }}
                                                                                >
                                                                                    {value}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </Menu.Items>
                                                            }
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="hidden lg:block bg-newlndgray mt-5 py-5 w-[450px] rounded-xl space-x-5">
                                            <p className="font-bold px-5 pb-5">{title.includes('Seat Belt Coloring') ? 'Select Color' : 'Your Vehicle'}</p>
                                            <div className="flex space-x-5">
                                                <div className="border border-gray-600 rounded-xl bg-white">
                                                    <Menu as="div" className="relative inline-block text-left md:w-48">
                                                        {title.includes('Seat Belt Coloring') ? (
                                                            <div>
                                                                <Menu.Button className="inline-flex justify-center gap-x-12 px-3 py-4 text-xs xl:text-sm font-semibold">
                                                                    {IsSelected ? variant.title : options[0].name}
                                                                    <Chevron />
                                                                </Menu.Button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <Menu.Button className="inline-flex justify-center gap-x-12 px-3 py-4 text-xs xl:text-sm font-semibold">
                                                                    {IsSelected ? variant.title : options[0].name}
                                                                    <Chevron />
                                                                </Menu.Button>
                                                            </div>
                                                        )
                                                        }
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            {isSeatBeltColor ?
                                                                <Menu.Items className="absolute right-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    {options[0].values.map((value, index) => (
                                                                        <Menu.Item key={`${index}`}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                                                    onClick={() => {
                                                                                        handleOptionChange(0, value)
                                                                                    }}
                                                                                >
                                                                                    {value}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </Menu.Items>
                                                                :
                                                                <Menu.Items className="absolute right-0 top-10 mt-2 z-10 w-56 h-64 overflow-y-auto scrollbar-w-2 scrollbar-track-gray-400 scrollbar-thumb-gray-600 origin-top-right rounded-md bg-newlndgray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    {options[0].values.slice(1).map((value, index) => (
                                                                        <Menu.Item key={`${index}`}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={classNames(active ? 'bg-gray-300 text-lndred' : 'text-black', 'block px-4 py-2 text-sm w-full text-left')}
                                                                                    onClick={() => {
                                                                                        handleOptionChange(0, value)
                                                                                    }}
                                                                                >
                                                                                    {value}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </Menu.Items>
                                                            }
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="lg:hidden mx-auto flex justify-center md:justify-start md:px-10"> <SearchBarProduct /></div>
                                        <div className="hidden lg:block"> <SearchBarProduct /></div>
                                    </>)}

                                <div className="lg:hidden pt-7 pb-1">
                                    <div className="flex space-x-5 mx-auto justify-center md:justify-start md:px-10">
                                        <NumericInput
                                            aria-label="Quantity"
                                            onIncrement={() => setQuantity((q) => Math.min(q + 1, 100))}
                                            onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                                            onChange={(event) => setQuantity(event.currentTarget.value)}
                                            value={quantity}
                                            min="1"
                                            max="100"
                                        />
                                        <div className="-mt-4">
                                            <AddToCart
                                                variantId={productVariant.storefrontId}
                                                quantity={quantity}
                                                IsSelected={IsSelected}
                                                variants={variants}
                                                hasVariants={hasVariants}
                                                isSeatBeltColor={isSeatBeltColor}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="hidden lg:block pt-7 pb-1">
                                    <div className="flex space-x-5">
                                        <NumericInput
                                            aria-label="Quantity"
                                            onIncrement={() => setQuantity((q) => Math.min(q + 1, 100))}
                                            onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                                            onChange={(event) => setQuantity(event.currentTarget.value)}
                                            value={quantity}
                                            min="1"
                                            max="100"
                                        />
                                        <div className="-mt-4">
                                            <AddToCart
                                                variantId={productVariant.storefrontId}
                                                quantity={quantity}
                                                IsSelected={IsSelected}
                                                variants={variants}
                                                hasVariants={hasVariants}
                                                isSeatBeltColor={isSeatBeltColor}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="lg:hidden px-12 pt-5">
                                    {IsSelected ? (
                                        <p className="text-3xl py-3 font-bold text-lndred text-left">{price}</p>
                                    ) : (
                                        <div className="text-lndred font-bold text-left">
                                            {minVariantPrice === maxVariantPrice ? (
                                                <p className="text-3xl py-3 font-bold">{minVariantPrice}</p>
                                            ) : (
                                                <p className="text-3xl py-3 font-bold">{minVariantPrice} - {maxVariantPrice}</p>
                                            )}
                                        </div>
                                    )}
                                </div> */}
                                {variants.length > 1 && isSeatbeltRepair ? (
                                    variants.length === 2 ? (
                                        <div>
                                            <div className="flex justify-center lg:justify-start space-x-3 pt-5 pb-2">
                                                <div>
                                                    <button onClick={(event) => handleOptionChange(1, event)} title="Type 1 Seat Belt" alt="Type 1 Seat Belt" className={`font-semibold relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type1Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                        <span>Type 1</span>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button onClick={(event) => handleOptionChange(2, event)} title="Type 2 Seat Belt" alt="Type 2 Seat Belt" className={`font-semibold relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type2Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                        <span>Type 2</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (variants.length === 3 ? (
                                        <div className="flex justify-center lg:justify-start space-x-3 pt-5 pb-2">
                                            <div>
                                                <button onClick={(event) => handleOptionChange(1, event)} title="Type 1 Seat Belt" alt="Type 1 Seat Belt" className={`font-semibold relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type1Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 1</span>
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={(event) => handleOptionChange(2, event)} title="Type 2 Seat Belt" alt="Type 2 Seat Belt" className={`font-semibold relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type2Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 2</span>

                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={(event) => handleOptionChange(3, event)} title="Type 3 Seat Belt" alt="Type 3 Seat Belt" className={`font-semibold relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type3Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 3</span>
                                                </button>
                                            </div>
                                        </div>
                                    ) : (variants.length === 4 ? (
                                        <div className="flex justify-center lg:justify-start space-x-2 sm:space-x-3 pt-5 pb-2">
                                            <div>
                                                <button onClick={(event) => handleOptionChange(1, event)} title="Type 1 Seat Belt" alt="Type 1 Seat Belt" className={`font-semibold relative h-10 py-2 px-2 md:px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type1Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 1</span>
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={(event) => handleOptionChange(2, event)} title="Type 2 Seat Belt" alt="Type 2 Seat Belt" className={`font-semibold relative h-10 py-2 px-2 md:px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type2Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 2</span>

                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={(event) => handleOptionChange(3, event)} title="Type 3 Seat Belt" alt="Type 3 Seat Belt" className={`font-semibold relative h-10 py-2 px-2 md:px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type3Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 3</span>
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={(event) => handleOptionChange(4, event)} title="Type 4 Seat Belt" alt="Type 4 Seat Belt" className={`font-semibold relative h-10 py-2 px-2 md:px-5 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline ${type4Selected ? "bg-lndred text-white" : "bg-transparent text-lndred hover:bg-lndred hover:text-white transition duration-150 ease-in-out"} `}>
                                                    <span>Type 4</span>
                                                </button>
                                            </div>
                                        </div>
                                    ) : null))
                                ) : null}
                                {variants.length > 1 && isAirbagPlugs ? (
                                    vehicleMakeAirbagPlugs === "Buick" || vehicleMakeAirbagPlugs === "Cadillac" || vehicleMakeAirbagPlugs === "Chevrolet" ? (
                                        <div>
                                            <fieldset title="Select Your Airbag Plug Replacement Type" alt="Select Your Airbag Plug Replacement Type" className="max-w-xs pt-1 mx-auto lg:mx-0">
                                                <div className={selectVariant} >
                                                    <div>
                                                        <select
                                                            aria-label="Variants"
                                                            id="airbagTypes"
                                                            onChange={(event) => handleOptionChange(document.getElementById('airbagTypes').value, event)}
                                                            className="shadow-lg rounded-lg">
                                                            <option selected="selected" disabled>{`Select Plug Type`}</option>
                                                            <option value={1 + " / Buick / Cadillac / Chevrolet"}>Type 1</option>
                                                            <option value={2 + " / Buick / Cadillac / Chevrolet"}>Type 2</option>
                                                            <option value={3 + " / Buick / Cadillac / Chevrolet"}>Type 3</option>
                                                            <option value={4 + " / Buick / Cadillac / Chevrolet"}>Type 4</option>
                                                            <option value={5 + " / Buick / Cadillac / Chevrolet"}>Type 5</option>
                                                            <option value={6 + " / Buick / Cadillac / Chevrolet"}>Type 6</option>
                                                            <option value={7 + " / Buick / Cadillac / Chevrolet"}>Type 7</option>
                                                            <option value={8 + " / Buick / Cadillac / Chevrolet"}>Type 8</option>
                                                            <option value={9 + " / Buick / Cadillac / Chevrolet"}>Type 9</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) : vehicleMakeAirbagPlugs === "GMC" || vehicleMakeAirbagPlugs === "Hyundai" || vehicleMakeAirbagPlugs === "Jeep" || vehicleMakeAirbagPlugs === "Kia" ? (
                                        <div>
                                            <fieldset title="Select Your Airbag Plug Replacement Type" alt="Select Your Airbag Plug Replacement Type" className="max-w-xs pt-1 mx-auto lg:mx-0">
                                                <div className={selectVariant} >
                                                    <div>
                                                        <select
                                                            aria-label="Variants"
                                                            id="airbagTypes"
                                                            onChange={(event) => handleOptionChange(document.getElementById('airbagTypes').value, event)}
                                                            className="shadow-lg rounded-lg">
                                                            <option selected="selected" disabled>{`Select Plug Type`}</option>
                                                            <option value={1 + " / GMC / Hyundai / Jeep / Kia"}>Type 1</option>
                                                            <option value={2 + " / GMC / Hyundai / Jeep / Kia"}>Type 2</option>
                                                            <option value={3 + " / GMC / Hyundai / Jeep / Kia"}>Type 3</option>
                                                            <option value={4 + " / GMC / Hyundai / Jeep / Kia"}>Type 4</option>
                                                            <option value={5 + " / GMC / Hyundai / Jeep / Kia"}>Type 5</option>
                                                            <option value={6 + " / GMC / Hyundai / Jeep / Kia"}>Type 6</option>
                                                            <option value={7 + " / GMC / Hyundai / Jeep / Kia"}>Type 7</option>
                                                            <option value={8 + " / GMC / Hyundai / Jeep / Kia"}>Type 8</option>
                                                            <option value={9 + " / GMC / Hyundai / Jeep / Kia"}>Type 9</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) : (vehicleMakeAirbagPlugs === "Chrysler" || vehicleMakeAirbagPlugs === "Dodge" || vehicleMakeAirbagPlugs === "Ford" ? (
                                        <div>
                                            <fieldset title="Select Your Airbag Plug Replacement Type" alt="Select Your Airbag Plug Replacement Type" className="max-w-xs pt-1 mx-auto lg:mx-0">
                                                <div className={selectVariant} >
                                                    <div>
                                                        <select
                                                            aria-label="Variants"
                                                            id="airbagTypes"
                                                            onChange={(event) => handleOptionChange(document.getElementById('airbagTypes').value, event)}
                                                            className="shadow-lg rounded-lg">
                                                            <option selected="selected" disabled>{`Select Plug Type`}</option>
                                                            <option value={1 + " / Chrysler / Dodge / Ford"}>Type 1</option>
                                                            <option value={2 + " / Chrysler / Dodge / Ford"}>Type 2</option>
                                                            <option value={3 + " / Chrysler / Dodge / Ford"}>Type 3</option>
                                                            <option value={4 + " / Chrysler / Dodge / Ford"}>Type 4</option>
                                                            <option value={5 + " / Chrysler / Dodge / Ford"}>Type 5</option>
                                                            <option value={6 + " / Chrysler / Dodge / Ford"}>Type 6</option>
                                                            <option value={7 + " / Chrysler / Dodge / Ford"}>Type 7</option>
                                                            <option value={8 + " / Chrysler / Dodge / Ford"}>Type 8</option>
                                                            <option value={9 + " / Chrysler / Dodge / Ford"}>Type 9</option>
                                                            <option value={10 + " / Chrysler / Dodge / Ford"}>Type 10</option>
                                                            <option value={11 + " / Chrysler / Dodge / Ford"}>Type 11</option>
                                                            <option value={12 + " / Chrysler / Dodge / Ford"}>Type 12</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) : (vehicleMakeAirbagPlugs === "GM" || vehicleMakeAirbagPlugs === "Infiniti" ? (
                                        <div>
                                            <fieldset title="Select Your Airbag Plug Replacement Type" alt="Select Your Airbag Plug Replacement Type" className="max-w-xs pt-1 mx-auto lg:mx-0">
                                                <div className={selectVariant} >
                                                    <div>
                                                        <select
                                                            aria-label="Variants"
                                                            id="airbagTypes"
                                                            onChange={(event) => handleOptionChange(document.getElementById('airbagTypes').value, event)}
                                                            className="shadow-lg rounded-lg">
                                                            <option selected="selected" disabled>{`Select Plug Type`}</option>
                                                            <option value={1 + " / " + vehicleMakeAirbagPlugs}>Type 1</option>
                                                            <option value={2 + " / " + vehicleMakeAirbagPlugs}>Type 2</option>
                                                            <option value={3 + " / " + vehicleMakeAirbagPlugs}>Type 3</option>
                                                            <option value={4 + " / " + vehicleMakeAirbagPlugs}>Type 4</option>
                                                            <option value={5 + " / " + vehicleMakeAirbagPlugs}>Type 5</option>
                                                            <option value={6 + " / " + vehicleMakeAirbagPlugs}>Type 6</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) : vehicleMakeAirbagPlugs === "Lexus" || vehicleMakeAirbagPlugs === "Toyota" ? (
                                        <div>
                                            <fieldset title="Select Your Airbag Plug Replacement Type" alt="Select Your Airbag Plug Replacement Type" className="max-w-xs pt-1 mx-auto lg:mx-0">
                                                <div className={selectVariant} >
                                                    <div>
                                                        <select
                                                            aria-label="Variants"
                                                            id="airbagTypes"
                                                            onChange={(event) => handleOptionChange(document.getElementById('airbagTypes').value, event)}
                                                            className="shadow-lg rounded-lg">
                                                            <option selected="selected" disabled>{`Select Plug Type`}</option>
                                                            <option value={1 + " / Lexus / Toyota"}>Type 1</option>
                                                            <option value={2 + " / Lexus / Toyota"}>Type 2</option>
                                                            <option value={3 + " / Lexus / Toyota"}>Type 3</option>
                                                            <option value={4 + " / Lexus / Toyota"}>Type 4</option>
                                                            <option value={5 + " / Lexus / Toyota"}>Type 5</option>
                                                            <option value={6 + " / Lexus / Toyota"}>Type 6</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    ) : null))
                                ) : null}
                            </div>
                            {/* {isSeatbeltRepair ? (
                            <div>
                                <TypesPopup vehicleMake={vehicleMake} title={title} />
                            </div>
                        ) : null} */}

                            <div className={`${isAirbagPlugsProduct ? 'translate-x-[20px]' : 'translate-x-[50px]'} pt-5 pb-0 text-left md:translate-x-[0px] md:px-12 lg:px-0`}>
                                <span className='font-bold text-2xl'>You May Also Need</span>
                                {title.includes('Single Stage') ?
                                    (
                                        <div className='grid pt-5'>
                                            <div>
                                                <div className='inline-flex'>
                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/dual-stage.png" />
                                                    <div className="flex flex-col">
                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Dual Stage Seat Belt Repair</p>
                                                        <div className='w-fit px-5'>
                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                <div className="pt-3 pb-2 text-sm">
                                                                    <NumericInput
                                                                        aria-label="Quantity"
                                                                        onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                                        onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                                        onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                                        value={quantitySuggested}
                                                                        min="1"
                                                                        max="100"
                                                                        isYouMayNeed />
                                                                </div>

                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583355490559', quantitySuggested, true, false) }}
                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                    <span>Add Repair</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='inline-flex'>
                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/triple-stage.png" />
                                                    <div className="flex flex-col">
                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$109.99</b> Triple Stage Seat Belt Repair</p>
                                                        <div className='w-fit px-5'>
                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                <div className="pt-3 pb-2 text-sm">
                                                                    <NumericInput
                                                                        aria-label="Quantity"
                                                                        onIncrement={() => setQuantitySuggested2((q) => Math.min(q + 1, 100))}
                                                                        onDecrement={() => setQuantitySuggested2((q) => Math.max(1, q - 1))}
                                                                        onChange={(event) => setQuantitySuggested2(event.currentTarget.value)}
                                                                        value={quantitySuggested2}
                                                                        min="1"
                                                                        max="100"
                                                                        isYouMayNeed />
                                                                </div>

                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583368990975', quantitySuggested2, true, false) }}
                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                    <span>Add Repair</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='inline-flex'>
                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-module.png" />
                                                    <div className="flex flex-col">
                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$54.99</b> Airbag Module Reset</p>
                                                        <div className='w-fit px-5'>
                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                <div className="pt-3 pb-2 text-sm">
                                                                    <NumericInput
                                                                        aria-label="Quantity"
                                                                        onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                        onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                        onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                        value={quantitySuggested3}
                                                                        min="1"
                                                                        max="100"
                                                                        isYouMayNeed />
                                                                </div>

                                                                <>
                                                                    {disabledAddButton ?
                                                                        <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416717320447', quantitySuggested3, true, false) }}
                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                            <span>Add Repair</span>
                                                                        </button>
                                                                        :
                                                                        <button onClick={() => { addToCart(displayName, quantitySuggested3, true, false) }}
                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                            <span>Add Repair</span>
                                                                        </button>
                                                                    }
                                                                </>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='inline-flex'>
                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto border" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-plugs.png" />
                                                    <div className="flex flex-col">
                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$21.99</b> Airbag Plugs</p>
                                                        <div className='w-fit px-5'>
                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                <div className="pt-3 pb-2 text-sm">
                                                                    <NumericInput
                                                                        aria-label="Quantity"
                                                                        onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                        onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                        onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                        value={quantitySuggested3}
                                                                        min="1"
                                                                        max="100"
                                                                        isYouMayNeed />
                                                                </div>

                                                                <>
                                                                    {disabledAddButton ?
                                                                        <button onClick={() => { addToCart('gid://shopify/ProductVariant/43483548451071', quantitySuggested3, true, false) }}
                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                            <span>Add Plugs</span>
                                                                        </button>
                                                                        :
                                                                        <button onClick={() => { addToCart(displayNameAirbagPlugs, quantitySuggested3, true, false) }}
                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                            <span>Add Plugs</span>
                                                                        </button>
                                                                    }
                                                                </>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                    :
                                    title.includes('Dual Stage') ?
                                        (
                                            <div className='grid pt-5'>
                                                <div>
                                                    <div className='inline-flex'>
                                                        <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/single-stage.png" />
                                                        <div className="flex flex-col">
                                                            <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Single Stage Seat Belt Repair</p>
                                                            <div className='w-fit px-5'>
                                                                <div className="flex space-x-5 md:block md:space-x-0">
                                                                    <div className="pt-3 pb-2 text-sm">
                                                                        <NumericInput
                                                                            aria-label="Quantity"
                                                                            onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                                            onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                                            onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                                            value={quantitySuggested}
                                                                            min="1"
                                                                            max="100"
                                                                            isYouMayNeed />
                                                                    </div>

                                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/42587634270463', quantitySuggested, true, false) }}
                                                                        className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                        <span>Add Repair</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='inline-flex'>
                                                        <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/triple-stage.png" />
                                                        <div className="flex flex-col">
                                                            <p className='px-2 pt-2 w-64 md:w-full'><b>$109.99</b> Triple Stage Seat Belt Repair</p>
                                                            <div className='w-fit px-5'>
                                                                <div className="flex space-x-5 md:block md:space-x-0">
                                                                    <div className="pt-3 pb-2 text-sm">
                                                                        <NumericInput
                                                                            aria-label="Quantity"
                                                                            onIncrement={() => setQuantitySuggested2((q) => Math.min(q + 1, 100))}
                                                                            onDecrement={() => setQuantitySuggested2((q) => Math.max(1, q - 1))}
                                                                            onChange={(event) => setQuantitySuggested2(event.currentTarget.value)}
                                                                            value={quantitySuggested2}
                                                                            min="1"
                                                                            max="100"
                                                                            isYouMayNeed />
                                                                    </div>

                                                                    <button onClick={() => { addToCart(`gid://shopify/ProductVariant/42583368990975`, quantitySuggested2, true, false) }}
                                                                        className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                        <span>Add Repair</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='inline-flex'>
                                                        <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-module.png" />
                                                        <div className="flex flex-col">
                                                            <p className='px-2 pt-2 w-64 md:w-full'><b>$54.99</b> Airbag Module Reset</p>
                                                            <div className='w-fit px-5'>
                                                                <div className="flex space-x-5 md:block md:space-x-0">
                                                                    <div className="pt-3 pb-2 text-sm">
                                                                        <NumericInput
                                                                            aria-label="Quantity"
                                                                            onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                            onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                            onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                            value={quantitySuggested3}
                                                                            min="1"
                                                                            max="100"
                                                                            isYouMayNeed />
                                                                    </div>

                                                                    <>
                                                                        {disabledAddButton ?
                                                                            <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416717320447', quantitySuggested3, true, false) }}
                                                                                className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                <span>Add Repair</span>
                                                                            </button>
                                                                            :
                                                                            <button onClick={() => { addToCart(displayName, quantitySuggested3, true, false) }}
                                                                                className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                <span>Add Repair</span>
                                                                            </button>
                                                                        }
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='inline-flex'>
                                                        <StaticImage className="w-24 md:w-32 rounded-xl mx-auto border" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-plugs.png" />
                                                        <div className="flex flex-col">
                                                            <p className='px-2 pt-2 w-64 md:w-full'><b>$21.99</b> Airbag Plugs</p>
                                                            <div className='w-fit px-5'>
                                                                <div className="flex space-x-5 md:block md:space-x-0">
                                                                    <div className="pt-3 pb-2 text-sm">
                                                                        <NumericInput
                                                                            aria-label="Quantity"
                                                                            onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                            onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                            onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                            value={quantitySuggested3}
                                                                            min="1"
                                                                            max="100"
                                                                            isYouMayNeed />
                                                                    </div>

                                                                    <>
                                                                        {disabledAddButton ?
                                                                            <button onClick={() => { addToCart('gid://shopify/ProductVariant/43483548451071', quantitySuggested3, true, false) }}
                                                                                className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                <span>Add Plugs</span>
                                                                            </button>
                                                                            :
                                                                            <button onClick={() => { addToCart(displayNameAirbagPlugs, quantitySuggested3, true, false) }}
                                                                                className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                <span>Add Plugs</span>
                                                                            </button>
                                                                        }
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ) :
                                        title.includes('Triple Stage') || title.includes('Pretensioner') ?
                                            (
                                                <div className='grid pt-5'>
                                                    <div>
                                                        <div className='inline-flex'>
                                                            <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/single-stage.png" />
                                                            <div className="flex flex-col">
                                                                <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Single Stage Seat Belt Repair</p>
                                                                <div className='w-fit px-5'>
                                                                    <div className="flex space-x-5 md:block md:space-x-0">
                                                                        <div className="pt-3 pb-2 text-sm">
                                                                            <NumericInput
                                                                                aria-label="Quantity"
                                                                                onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                                                onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                                                onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                                                value={quantitySuggested}
                                                                                min="1"
                                                                                max="100"
                                                                                isYouMayNeed />
                                                                        </div>

                                                                        <button onClick={() => { addToCart('gid://shopify/ProductVariant/42587634270463', quantitySuggested, true, false) }}
                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                            <span>Add Repair</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className='inline-flex'>
                                                            <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/dual-stage.png" />
                                                            <div className="flex flex-col">
                                                                <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Dual Stage Seat Belt Repair</p>
                                                                <div className='w-fit px-5'>
                                                                    <div className="flex space-x-5 md:block md:space-x-0">
                                                                        <div className="pt-3 pb-2 text-sm">
                                                                            <NumericInput
                                                                                aria-label="Quantity"
                                                                                onIncrement={() => setQuantitySuggested2((q) => Math.min(q + 1, 100))}
                                                                                onDecrement={() => setQuantitySuggested2((q) => Math.max(1, q - 1))}
                                                                                onChange={(event) => setQuantitySuggested2(event.currentTarget.value)}
                                                                                value={quantitySuggested2}
                                                                                min="1"
                                                                                max="100"
                                                                                isYouMayNeed />
                                                                        </div>

                                                                        <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583355490559', quantitySuggested2, true, false) }}
                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                            <span>Add Repair</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className='inline-flex'>
                                                            <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-module.png" />
                                                            <div className="flex flex-col">
                                                                <p className='px-2 pt-2 w-64 md:w-full'><b>$54.99</b> Airbag Module Reset</p>
                                                                <div className='w-fit px-5'>
                                                                    <div className="flex space-x-5 md:block md:space-x-0">
                                                                        <div className="pt-3 pb-2 text-sm">
                                                                            <NumericInput
                                                                                aria-label="Quantity"
                                                                                onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                value={quantitySuggested3}
                                                                                min="1"
                                                                                max="100"
                                                                                isYouMayNeed />
                                                                        </div>

                                                                        <>
                                                                            {disabledAddButton ?
                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416717320447', quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                                :
                                                                                <button onClick={() => { addToCart(displayName, quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            }
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className='inline-flex'>
                                                            <StaticImage className="w-24 md:w-32 rounded-xl mx-auto border" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-plugs.png" />
                                                            <div className="flex flex-col">
                                                                <p className='px-2 pt-2 w-64 md:w-full'><b>$21.99</b> Airbag Plugs</p>
                                                                <div className='w-fit px-5'>
                                                                    <div className="flex space-x-5 md:block md:space-x-0">
                                                                        <div className="pt-3 pb-2 text-sm">
                                                                            <NumericInput
                                                                                aria-label="Quantity"
                                                                                onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                value={quantitySuggested3}
                                                                                min="1"
                                                                                max="100"
                                                                                isYouMayNeed />
                                                                        </div>

                                                                        <>
                                                                            {disabledAddButton ?
                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/43483548451071', quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Plugs</span>
                                                                                </button>
                                                                                :
                                                                                <button onClick={() => { addToCart(displayNameAirbagPlugs, quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Plugs</span>
                                                                                </button>
                                                                            }
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) :
                                            title.includes('Battery Cable') || title.includes('Steering Sensor') || title.includes('Roll Bar') || title.includes('Deployed Headrest') ?
                                                (
                                                    <div className='grid pt-5 translate-x-[-40px] px-10 md:px-0 md:translate-x-[0px]'>
                                                        <div>
                                                            <div className='inline-flex'>
                                                                <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/single-stage.png" />
                                                                <div className="flex flex-col">
                                                                    <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Single Stage Seat Belt Repair</p>
                                                                    <div className='w-fit px-5'>
                                                                        <div className="flex space-x-5 md:block md:space-x-0">
                                                                            <div className="pt-3 pb-2 text-sm">
                                                                                <NumericInput
                                                                                    aria-label="Quantity"
                                                                                    onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                                                    onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                                                    onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                                                    value={quantitySuggested}
                                                                                    min="1"
                                                                                    max="100"
                                                                                    isYouMayNeed />
                                                                            </div>

                                                                            <button onClick={() => { addToCart('gid://shopify/ProductVariant/42587634270463', quantitySuggested, true, false) }}
                                                                                className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                <span>Add Repair</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='inline-flex'>
                                                                <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/dual-stage.png" />
                                                                <div className="flex flex-col">
                                                                    <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Dual Stage Seat Belt Repair</p>
                                                                    <div className='w-fit px-5'>
                                                                        <div className="flex space-x-5 md:block md:space-x-0">
                                                                            <div className="pt-3 pb-2 text-sm">
                                                                                <NumericInput
                                                                                    aria-label="Quantity"
                                                                                    onIncrement={() => setQuantitySuggested2((q) => Math.min(q + 1, 100))}
                                                                                    onDecrement={() => setQuantitySuggested2((q) => Math.max(1, q - 1))}
                                                                                    onChange={(event) => setQuantitySuggested2(event.currentTarget.value)}
                                                                                    value={quantitySuggested2}
                                                                                    min="1"
                                                                                    max="100"
                                                                                    isYouMayNeed />
                                                                            </div>

                                                                            <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583355490559', quantitySuggested2, true, false) }}
                                                                                className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                <span>Add Repair</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='inline-flex'>
                                                                <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-module.png" />
                                                                <div className="flex flex-col">
                                                                    <p className='px-2 pt-2 w-64 md:w-full'><b>$54.99</b> Airbag Module Reset</p>
                                                                    <div className='w-fit px-5'>
                                                                        <div className="flex space-x-5 md:block md:space-x-0">
                                                                            <div className="pt-3 pb-2 text-sm">
                                                                                <NumericInput
                                                                                    aria-label="Quantity"
                                                                                    onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                    onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                    onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                    value={quantitySuggested3}
                                                                                    min="1"
                                                                                    max="100"
                                                                                    isYouMayNeed />
                                                                            </div>

                                                                            {!IsSelected ?
                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416717320447', quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                                :
                                                                                <button onClick={() => { addToCart(displayName, quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='inline-flex'>
                                                                <StaticImage className="w-24 md:w-32 rounded-xl mx-auto border" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-plugs.png" />
                                                                <div className="flex flex-col">
                                                                    <p className='px-2 pt-2 w-64 md:w-full'><b>$21.99</b> Airbag Plugs</p>
                                                                    <div className='w-fit px-5'>
                                                                        <div className="flex space-x-5 md:block md:space-x-0">
                                                                            <div className="pt-3 pb-2 text-sm">
                                                                                <NumericInput
                                                                                    aria-label="Quantity"
                                                                                    onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                    onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                    onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                    value={quantitySuggested3}
                                                                                    min="1"
                                                                                    max="100"
                                                                                    isYouMayNeed />
                                                                            </div>

                                                                            <>
                                                                                {disabledAddButton ?
                                                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/43483548451071', quantitySuggested3, true, false) }}
                                                                                        className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                        <span>Add Plugs</span>
                                                                                    </button>
                                                                                    :
                                                                                    <button onClick={() => { addToCart(displayNameAirbagPlugs, quantitySuggested3, true, false) }}
                                                                                        className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                        <span>Add Plugs</span>
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ) :
                                                title.includes('Airbag Module Reset') ?
                                                    (
                                                        <div className='grid pt-5'>
                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/single-stage.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Single Stage Seat Belt Repair</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                                                        value={quantitySuggested}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42587634270463', quantitySuggested, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/dual-stage.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Dual Stage Seat Belt Repair</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested2((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested2((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested2(event.currentTarget.value)}
                                                                                        value={quantitySuggested2}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583355490559', quantitySuggested2, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/pretensioner.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$69.99</b> Pretensioner Repair</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                        value={quantitySuggested3}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583487283455', quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto border" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/airbag-plugs.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$21.99</b> Airbag Plugs</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                        value={quantitySuggested3}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <>
                                                                                    {disabledAddButton ?
                                                                                        <button onClick={() => { addToCart('gid://shopify/ProductVariant/43483548451071', quantitySuggested3, true, false) }}
                                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                            <span>Add Plugs</span>
                                                                                        </button>
                                                                                        :
                                                                                        <button onClick={() => { addToCart(displayNameAirbagPlugs, quantitySuggested3, true, false) }}
                                                                                            className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                            <span>Add Plugs</span>
                                                                                        </button>
                                                                                    }
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        <div className='grid pt-5'>
                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/single-stage.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Single Stage Seat Belt Repair</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                                                        value={quantitySuggested}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42587634270463', quantitySuggested, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/dual-stage.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$94.99</b> Dual Stage Seat Belt Repair</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested2((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested2((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested2(event.currentTarget.value)}
                                                                                        value={quantitySuggested2}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583355490559', quantitySuggested2, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className='inline-flex'>
                                                                    <StaticImage className="w-24 md:w-32 rounded-xl mx-auto" title="Shopping Bag" alt="Place your order with L&D Solutions" placeholder="none" src="../images/pretensioner.png" />
                                                                    <div className="flex flex-col">
                                                                        <p className='px-2 pt-2 w-64 md:w-full'><b>$109.99</b> Triple Stage Seat Belt Repair</p>
                                                                        <div className='w-fit px-5'>
                                                                            <div className="flex space-x-5 md:block md:space-x-0">
                                                                                <div className="pt-3 pb-2 text-sm">
                                                                                    <NumericInput
                                                                                        aria-label="Quantity"
                                                                                        onIncrement={() => setQuantitySuggested3((q) => Math.min(q + 1, 100))}
                                                                                        onDecrement={() => setQuantitySuggested3((q) => Math.max(1, q - 1))}
                                                                                        onChange={(event) => setQuantitySuggested3(event.currentTarget.value)}
                                                                                        value={quantitySuggested3}
                                                                                        min="1"
                                                                                        max="100"
                                                                                        isYouMayNeed />
                                                                                </div>

                                                                                <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583368990975', quantitySuggested3, true, false) }}
                                                                                    className='mt-2 relative text-xs md:text-sm py-2 px-2 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                                                    <span>Add Repair</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                }
                            </div>
                            <div className="lg:hidden text-left px-5 md:px-10 pt-5 max-w-sm mx-auto md:max-w-full">
                                {/* <h2 className="text-3xl font-semibold pb-5">{title} Service</h2>
                                <p className="max-w-xl">{description1}</p>
                                <p className="max-w-xl pt-5">{description2}</p> */}
                                {/* <div className="mx-auto pt-8">
                                    <BannerThreeStage />
                                </div> */}
                                <div className='mt-10 py-5 px-5 lg:px-0 xl:ml-3 2xl:ml-10 text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl bg-[#FAFBFC] border rounded-xl border-[#E0E8F6]'>
                                    <div className="lg:px-5 pb-5">
                                        <div className="flex gap-x-3">
                                            <Info />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{contentfulVariable[0].node.specificationsTitle1}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: contentfulVariable[0].node.specificationsBody1.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>
                                    <div className="border-t py-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <Trophy />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{contentfulVariable[0].node.specificationsTitle2}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: contentfulVariable[0].node.specificationsBody2.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>

                                    <div className="border-t py-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <Light />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{contentfulVariable[0].node.specificationsTitle3}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: contentfulVariable[0].node.specificationsBody3.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>

                                    <div className="border-t py-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <Shield />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{contentfulVariable[0].node.specificationsTitle4}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: contentfulVariable[0].node.specificationsBody4.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>

                                    <div className="border-t pt-5">
                                        <div className="flex gap-x-3 lg:px-5">
                                            <StopSign />
                                            <h2 className="text-[#535F74] opacity-80 text-[22px] max-w-sm">{contentfulVariable[0].node.specificationsTitle5}</h2>
                                        </div>
                                        <div className="text-[#222732] opacity-80 text-[15px] pt-4 lg:px-5">
                                            <div className="max-w-xl 2xl:max-w-3xl" dangerouslySetInnerHTML={{
                                                __html: contentfulVariable[0].node.specificationsBody5.childMarkdownRemark.html,
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="flex space-x-3 justify-center lg:justify-start py-10">
                            <AddToCart
                                variantId={productVariant.storefrontId}
                                quantity={quantity}
                                IsSelected={IsSelected}
                                variants={variants}
                                hasVariants={hasVariants}
                            />
                        </div> */}
                            {/* <div className="font-bold text-lg">
                            <div className="flex justify-center lg:justify-start py-2">
                                <CheckMark />
                                <span className="pl-2">100% OEM Parts!</span>
                            </div>
                            <div className="flex justify-center lg:justify-start py-2">
                                <Warranty />
                                <span className="pl-2">Lifetime Warranty!</span>
                            </div>
                            <div className="flex justify-center lg:justify-start py-2">
                                <Fast />
                                <span className="pl-2">1 Day Turnaround!</span>
                            </div>
                            <div className="flex justify-center lg:justify-start py-2">
                                <Premium />
                                <span className="pl-2">Premium Quality Guaranteed!</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                {title.includes('Single Stage') && isOptionalTextSingleStage ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                    __html: singleStageData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                }} />
                    : title.includes('Dual Stage') && isOptionalTextDualStage ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                        __html: dualStageData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                    }} />
                        : title.includes('Triple Stage') && isOptionalTextTripleStage ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                            __html: tripleStageData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                        }} />
                            : title.includes('Pretensioner') && isOptionalTextPretensioner ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                __html: pretensionerData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                            }} />
                                : title.includes('Seat Belt Coloring') && isOptionalTextSeatBeltColor ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                    __html: seatBeltColorData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                                }} />
                                    : title.includes('Battery Cable') && isOptionalTextBatteryCable ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                        __html: batteryCableData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                                    }} />
                                        : title.includes('Airbag Plug') && isOptionalTextAirBagPlugs ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                            __html: airbagPlugsData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                                        }} />
                                            : title.includes('Collapsible Steering') && isOptionalTextSteeringColumn ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                                __html: steeringColumnData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                                            }} />
                                                : title.includes('Airbag Module') && isOptionalTextSRSAirbag ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                                    __html: srsAirBagData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                                                }} />
                                                    : title.includes('Convertible Roll Bar') && isOptionalTextConvertibleRollBar ? <div className="px-10 lg:px-5 xl:px-10 2xl:ml-5 pb-5" dangerouslySetInnerHTML={{
                                                        __html: convertibleRollBarData[0].node.optionalAdditionalText.childMarkdownRemark.html,
                                                    }} />
                                                        : ''}
                {/* <div className={`hidden lg:block py-5 lg:py-0 px-5 xl:ml-3 2xl:ml-10 text-center lg:text-left mb-5 max-w-lg xl:max-w-2xl 2xl:max-w-3xl ${title.includes('Single Stage Seat Belt Repair') ? 'lg:translate-y-[-665px] xl:translate-y-[-610px]' : title.includes('Dual Stage Seat Belt Repair') ? 'lg:translate-y-[-625px] xl:translate-y-[-610px]' : title.includes('Triple Stage Seat Belt Repair') ? 'lg:translate-y-[-660px] xl:translate-y-[-610px]' : title.includes('Pretensioner') ? 'lg:translate-y-[-625px] xl:translate-y-[-580px]' : title.includes('Seat Belt Coloring') ? 'lg:translate-y-[-570px] xl:translate-y-[-560px]' : title.includes('Collapsible Steering Sensor Repair') ? 'lg:translate-y-[-630px] xl:translate-y-[-610px]' : title.includes('Airbag Module Reset') ? 'lg:translate-y-[-640px] xl:translate-y-[-580px]' : title.includes('Roll Bar') ? 'lg:translate-y-[-725px] xl:translate-y-[-670px]' : title.includes('Deployed Headrest') ? 'translate-y-[-720px] xl:translate-y-[-670px]' : 'translate-y-[-710px] xl:translate-y-[-630px]'}`}>
                <h2 className="text-2xl lg:text-3xl font-semibold pb-5">{title} Service</h2>
                <p className="max-w-xl 2xl:max-w-3xl">{description1}</p>
                <p className="max-w-xl pt-5 2xl:max-w-3xl">{description2}</p>
                <div className="mx-auto pt-8">
                    <BannerThreeStage />
                </div>
            </div> */}
            </div>

            <div className="bg-[#FFE1D9] bg-opacity-10">
                <div className="container mx-auto mb-5">
                    <div className="py-8 lg:py-16 px-5">
                        <div className="border border-lndred border-[2px] w-[70px] translate-x-[22px] lg:translate-x-[0px] 2xl:translate-x-[130px] pb-2 rounded bg-lndred mb-2"></div>
                        <p className="font-bold text-3xl text-left 2xl:px-[130px] pb-5 translate-x-[22px] lg:translate-x-[0px]">How It Works</p>
                        <div className="grid md:grid-cols-2 xl:grid-cols-3 md:translate-x-[22px] lg:translate-x-[80px] xl:translate-x-[0px] 2xl:translate-x-[130px] gap-y-5 mx-auto justify-center">
                            <div className="w-80 py-2">
                                <div className="flex">
                                    <div className="bg-[#FFE1D9] flex items-center space-x-3 px-3 w-80">
                                        <span className="text-lndred text-3xl">1 </span>
                                        <div dangerouslySetInnerHTML={{
                                            __html: contentfulVariable[0].node.howItWorksTitle1.childMarkdownRemark.html,
                                        }} />
                                    </div>
                                    <div className="mt-2 translate-x-[20px] lg:translate-x-[90px] xl:translate-x-[50px] 2xl:translate-x-[90px] hidden md:block"><Arrow /></div>
                                </div>
                                <div className="pt-3 text-sm" dangerouslySetInnerHTML={{
                                    __html: contentfulVariable[0].node.howItWorksBody1.childMarkdownRemark.html,
                                }} />
                                <div className="translate-y-[16px] md:hidden"><Arrow /></div>
                            </div>

                            <div className="w-80 py-2">
                                <div className="flex">
                                    <div className="bg-[#FFE1D9] flex items-center space-x-3 px-3 w-80">
                                        <span className="text-lndred text-3xl">2 </span>
                                        <div dangerouslySetInnerHTML={{
                                            __html: contentfulVariable[0].node.howItWorksTitle2.childMarkdownRemark.html,
                                        }} />
                                    </div>
                                    <div className="mt-2 xl:translate-x-[50px] 2xl:translate-x-[90px] hidden xl:block"><Arrow /></div>
                                </div>
                                <div className="pt-3 text-sm" dangerouslySetInnerHTML={{
                                    __html: contentfulVariable[0].node.howItWorksBody2.childMarkdownRemark.html,
                                }} />
                                <div className="translate-y-[16px] md:hidden"><Arrow /></div>
                            </div>

                            <div className="w-80 py-2">
                                <div className="flex">
                                    <div className="bg-[#FFE1D9] flex items-center space-x-3 px-3 w-80">
                                        <span className="text-lndred text-3xl">3 </span>
                                        <div dangerouslySetInnerHTML={{
                                            __html: contentfulVariable[0].node.howItWorksTitle3.childMarkdownRemark.html,
                                        }} />
                                    </div>
                                    <div className="mt-2 translate-x-[20px] lg:translate-x-[90px] xl:translate-x-[50px] hidden md:block"><Arrow /></div>
                                </div>
                                <div className="pt-3 text-sm" dangerouslySetInnerHTML={{
                                    __html: contentfulVariable[0].node.howItWorksBody3.childMarkdownRemark.html,
                                }} />
                                <div className="translate-y-[16px] md:hidden"><Arrow /></div>
                            </div>

                            <div className="w-80 py-2">
                                <div className="flex">
                                    <div className="bg-[#FFE1D9] flex items-center space-x-3 px-3 w-80">
                                        <span className="text-lndred text-3xl">4 </span>
                                        <div dangerouslySetInnerHTML={{
                                            __html: contentfulVariable[0].node.howItWorksTitle4.childMarkdownRemark.html,
                                        }} />
                                    </div>
                                    <div className="mt-2 translate-x-[90px] xl:translate-x-[50px] 2xl:translate-x-[90px] hidden xl:block"><Arrow /></div>
                                </div>
                                <div className="pt-3 text-sm" dangerouslySetInnerHTML={{
                                    __html: contentfulVariable[0].node.howItWorksBody4.childMarkdownRemark.html,
                                }} />
                                <div className="translate-y-[16px] md:hidden"><Arrow /></div>
                            </div>

                            <div className="w-80 py-2">
                                <div className="flex">
                                    <div className="bg-[#FFE1D9] flex items-center space-x-3 px-3 w-80">
                                        <span className="text-lndred text-3xl">5 </span>
                                        <div dangerouslySetInnerHTML={{
                                            __html: contentfulVariable[0].node.howItWorksTitle5.childMarkdownRemark.html,
                                        }} />
                                    </div>
                                    <div className="mt-2 translate-x-[20px] lg:translate-x-[90px] xl:translate-x-[50px] 2xl:translate-x-[90px] hidden md:block"><Arrow /></div>
                                </div>
                                <div className="pt-3 text-sm" dangerouslySetInnerHTML={{
                                    __html: contentfulVariable[0].node.howItWorksBody5.childMarkdownRemark.html,
                                }} />
                                <div className="translate-y-[16px] md:hidden"><Arrow /></div>
                            </div>

                            <div className="w-80 py-2">
                                <div className="flex">
                                    <div className="bg-[#FFE1D9] flex items-center space-x-3 px-3 w-80">
                                        <span className="text-lndred text-3xl">6 </span>
                                        <div dangerouslySetInnerHTML={{
                                            __html: contentfulVariable[0].node.howItWorksTitle6.childMarkdownRemark.html,
                                        }} />
                                    </div>
                                </div>
                                <div className="pt-3 text-sm" dangerouslySetInnerHTML={{
                                    __html: contentfulVariable[0].node.howItWorksBody6.childMarkdownRemark.html,
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: `url(${background})` }}>
                <div className="container mx-auto mb-5">
                    <div className="py-8 lg:py-16 px-5">
                        <div className="border border-lndred border-[2px] w-[70px] 2xl:mx-auto pb-2 rounded bg-lndred mb-2 translate-x-[30px] sm:translate-x-[50px] lg:translate-x-[170px] xl:translate-x-[285px] 2xl:translate-x-[-300px]"></div>
                        <p className="font-bold text-3xl 2xl:text-center mb-5 translate-x-[30px] sm:translate-x-[50px] lg:translate-x-[170px] xl:translate-x-[285px] 2xl:translate-x-[-300px]">FAQ</p>
                        <div className="container ">
                            <div className="pb-16 px-5">
                                <section>
                                    <div className="container flex flex-col justify-center mx-auto">
                                        {contentfulVariable[0].node.faq.map((faq, index) => (
                                            <div key={index} className="flex flex-col sm:px-8 lg:px-12 xl:px-28 divide-gray-700 py-3 lg:translate-x-[100px] xl:translate-x-[150px] 2xl:translate-x-[270px]">

                                                <details className="bg-[#F3F6FB] bg-opacity-40 border-[1px] border-[#F3F6FB] rounded-md px-5 text-lg max-w-2xl">
                                                    <summary className="py-2 cursor-pointer text-[22px] opacity-90">{faq.question}</summary>
                                                    <div className="px-4 pb-4">
                                                        <p className="opacity-80 text-[16px] pt-3">{faq.answer}</p>
                                                    </div>
                                                </details>

                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-5">
                <form name={title + ' Service Page'}
                    method="POST"
                    data-netlify="true"
                    action="/success"
                    className="w-full max-w-3xl px-14 py-5 lg:px-6 xl:px-14 bg-white border-2 rounded-2xl mx-auto shadow-xl"
                >
                    <input type="hidden" name="form-name" value={title + ' Service Page'} />
                    <p className="text-center text-3xl font-bold mt-2">Fast Replies Guaranteed!</p>
                    <hr className="my-5 border border-gray-400" />
                    <div className="flex flex-wrap -mx-2 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">First Name
                                <span className="text-lndred">*</span>
                                <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="grid-first-name" type="text" name="first name" required placeholder="Ex: Jane" />
                            </label>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Last Name
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-lndred" id="grid-last-name" type="text" name="last name" placeholder="Ex: Doe" />
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="company-name">Company Name
                                <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="company-name" type="text" name="company" placeholder="Ex: L&D Solutions" />
                            </label>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-lndred" id="phone" name="phone" type="tel" placeholder="Ex: 123-456-7890" />
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="email">E-mail
                                <span className="text-lndred">*</span>
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred" id="email" type="email" name="email" required placeholder="Ex: JaneDoe@mail.com" />
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">Question / Message
                                <span className="text-lndred">*</span>
                                <textarea className="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-lndred h-48 resize-none" id="message" name="message" required placeholder="i.e. Questions, Comments, etc..."></textarea>
                            </label>
                            <p className="text-xs italic text-lndred">*Required</p>
                        </div>
                    </div>
                    <div className="flex text-sm items-center text-gray-300 justify-center text-center">
                        <Lock />
                        <span className="pl-2">
                            We Value Your Privacy
                        </span>
                    </div>
                    <div className="flex justify-center py-5 mx-auto">
                        <button type="submit" title="Send Message" alt="Send Message" className="shadow bg-lndred border hover:shadow-lg hover:shadow-lndred hover:border-lndred focus:shadow-outline focus:outline-none text-white font-semibold py-2 px-4 rounded-xl w-48 transition duration-150 ease-in-out" >Send Message</button>
                    </div>
                </form>
            </div>

            <div className="container mx-auto mb-10">
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-10 pt-14 pb-8 mx-auto xl:flex xl:flex-row items-start p-0 gap-8 w-full sm:justify-center">
                    {contentfulVariable[0].node.textColumns.map((textColumns, index) => (
                        <div key={index} className="flex flex-row items-start p-0 gap-2 max-w-xl sm:w-[190.4px] h-auto">
                            <div className="w-[4px] h-auto bg-[#F3F6FB] flex-none order-0 self-stretch flex-grow-0"></div>
                            <div className="flex flex-col items-start p-0 gap-2 w-[186.4px] h-auto flex-none order-1 flex-grow">
                                <div className="w-[186.4px] h-auto text-[#6D7E9A] font-inter font-normal text-lg leading-6 flex-none order-0 self-stretch flex-grow-0">
                                    {textColumns.title}
                                </div>
                                <div className="max-w-xl sm:w-[186.4px] h-auto text-[#655C5A] font-inter font-normal text-sm leading-4 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {textColumns.body}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}
export default ProductMainSection

function Bullet() {
    return (
        <svg className='mt-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8">
            <circle cx="12" cy="12" r="8" fill="currentColor" />
        </svg>)
}

const airbagID = [
    { car: 'Acura', storeFrontId: 'gid://shopify/ProductVariant/42827767415039' },
    { car: 'Alfa Romeo', storeFrontId: 'gid://shopify/ProductVariant/42827767447807' },
    { car: 'Aston Martin', storeFrontId: 'gid://shopify/ProductVariant/42827767480575' },
    { car: 'Audi', storeFrontId: 'gid://shopify/ProductVariant/42827767513343' },
    { car: 'Bentley', storeFrontId: 'gid://shopify/ProductVariant/42827767546111' },
    { car: 'BMW', storeFrontId: 'gid://shopify/ProductVariant/42827767578879' },
    { car: 'Buick', storeFrontId: 'gid://shopify/ProductVariant/42827767611647' },
    { car: 'Cadillac', storeFrontId: 'gid://shopify/ProductVariant/42827767644415' },
    { car: 'Chevrolet', storeFrontId: 'gid://shopify/ProductVariant/43659585782015' },
    { car: 'Chrysler', storeFrontId: 'gid://shopify/ProductVariant/42827767677183' },
    { car: 'Dodge', storeFrontId: 'gid://shopify/ProductVariant/42827767709951' },
    { car: 'Ferrari', storeFrontId: 'gid://shopify/ProductVariant/42827767742719' },
    { car: 'Fiat', storeFrontId: 'gid://shopify/ProductVariant/42827767775487' },
    { car: 'Ford', storeFrontId: 'gid://shopify/ProductVariant/42557822763263' },
    { car: 'GMC', storeFrontId: 'gid://shopify/ProductVariant/42827767808255' },
    { car: 'Honda', storeFrontId: 'gid://shopify/ProductVariant/42827767873791' },
    { car: 'Hyundai', storeFrontId: 'gid://shopify/ProductVariant/42827767906559' },
    { car: 'Infiniti', storeFrontId: 'gid://shopify/ProductVariant/42557803823359' },
    { car: 'Jaguar', storeFrontId: 'gid://shopify/ProductVariant/42827767939327' },
    { car: 'Jeep', storeFrontId: 'gid://shopify/ProductVariant/42827767972095' },
    { car: 'Kia', storeFrontId: 'gid://shopify/ProductVariant/42827768004863' },
    { car: 'Lamborghini', storeFrontId: 'gid://shopify/ProductVariant/42827768037631' },
    { car: 'Land Rover', storeFrontId: 'gid://shopify/ProductVariant/42827768070399' },
    { car: 'Lexus', storeFrontId: 'gid://shopify/ProductVariant/42827768103167' },
    { car: 'Maserati', storeFrontId: 'gid://shopify/ProductVariant/42827768135935' },
    { car: 'Mazda', storeFrontId: 'gid://shopify/ProductVariant/42557803856127' },
    { car: 'McLaren', storeFrontId: 'gid://shopify/ProductVariant/42827768168703' },
    { car: 'Mercedes Benz', storeFrontId: 'gid://shopify/ProductVariant/42827768201471' },
    { car: 'Mini Cooper', storeFrontId: 'gid://shopify/ProductVariant/42827768234239' },
    { car: 'Mitsubishi', storeFrontId: 'gid://shopify/ProductVariant/42827768267007' },
    { car: 'Nissan', storeFrontId: 'gid://shopify/ProductVariant/42557803888895' },
    { car: 'Porsche', storeFrontId: 'gid://shopify/ProductVariant/42827768299775' },
    { car: 'Rolls Royce', storeFrontId: 'gid://shopify/ProductVariant/42827768332543' },
    { car: 'Scion', storeFrontId: 'gid://shopify/ProductVariant/42827768365311' },
    { car: 'Subaru', storeFrontId: 'gid://shopify/ProductVariant/42557803921663' },
    { car: 'Tesla', storeFrontId: 'gid://shopify/ProductVariant/42827768398079' },
    { car: 'Toyota', storeFrontId: 'gid://shopify/ProductVariant/42557803954431' },
    { car: 'Volkswagen', storeFrontId: 'gid://shopify/ProductVariant/42827768430847' }
]

const airbagPlugID = [
    { car: 'Acura', storeFrontId: 'gid://shopify/ProductVariant/46068179632383' },
    { car: 'Alfa Romeo', storeFrontId: 'gid://shopify/ProductVariant/46068179665151' },
    { car: 'Aston Martin', storeFrontId: 'gid://shopify/ProductVariant/46068179697919' },
    { car: 'Audi', storeFrontId: 'gid://shopify/ProductVariant/46068179730687' },
    { car: 'Bentley', storeFrontId: 'gid://shopify/ProductVariant/46068179763455' },
    { car: 'BMW', storeFrontId: 'gid://shopify/ProductVariant/46068179796223' },
    { car: 'Buick', storeFrontId: 'gid://shopify/ProductVariant/46068179828991' },
    { car: 'Cadillac', storeFrontId: 'gid://shopify/ProductVariant/46068179861759' },
    { car: 'Chrysler', storeFrontId: 'gid://shopify/ProductVariant/46068179894527' },
    { car: 'Dodge', storeFrontId: 'gid://shopify/ProductVariant/46068179927295' },
    { car: 'Ferrari', storeFrontId: 'gid://shopify/ProductVariant/46068179960063' },
    { car: 'Fiat', storeFrontId: 'gid://shopify/ProductVariant/46068179992831' },
    { car: 'Ford', storeFrontId: 'gid://shopify/ProductVariant/46068180025599' },
    { car: 'GMC', storeFrontId: 'gid://shopify/ProductVariant/46068180058367' },
    { car: 'Honda', storeFrontId: 'gid://shopify/ProductVariant/46068180091135' },
    { car: 'Huyndai', storeFrontId: 'gid://shopify/ProductVariant/46068180123903' },
    { car: 'Infiniti', storeFrontId: 'gid://shopify/ProductVariant/46068180156671' },
    { car: 'Jaguar', storeFrontId: 'gid://shopify/ProductVariant/46068180189439' },
    { car: 'Jeep', storeFrontId: 'gid://shopify/ProductVariant/46068180222207' },
    { car: 'Kia', storeFrontId: 'gid://shopify/ProductVariant/46068180254975' },
    { car: 'Lamborghini', storeFrontId: 'gid://shopify/ProductVariant/46068180287743' },
    { car: 'Land Rover', storeFrontId: 'gid://shopify/ProductVariant/46068180320511' },
    { car: 'Lexus', storeFrontId: 'gid://shopify/ProductVariant/46068180353279' },
    { car: 'Maserati', storeFrontId: 'gid://shopify/ProductVariant/46068180386047' },
    { car: 'Mazda', storeFrontId: 'gid://shopify/ProductVariant/46068180418815' },
    { car: 'McLaren', storeFrontId: 'gid://shopify/ProductVariant/46068180451583' },
    { car: 'Mercedes Benz', storeFrontId: 'gid://shopify/ProductVariant/46068180484351' },
    { car: 'Mini Cooper', storeFrontId: 'gid://shopify/ProductVariant/46068180517119' },
    { car: 'Mitsubishi', storeFrontId: 'gid://shopify/ProductVariant/46068180549887' },
    { car: 'Nissan', storeFrontId: 'gid://shopify/ProductVariant/46068180582655' },
    { car: 'Porsche', storeFrontId: 'gid://shopify/ProductVariant/46068180615423' },
    { car: 'Rolls Royce', storeFrontId: 'gid://shopify/ProductVariant/46068180648191' },
    { car: 'Scion', storeFrontId: 'gid://shopify/ProductVariant/46068180680959' },
    { car: 'Subaru', storeFrontId: 'gid://shopify/ProductVariant/46068180713727' },
    { car: 'Tesla', storeFrontId: 'gid://shopify/ProductVariant/46068180746495' },
    { car: 'Toyota', storeFrontId: 'gid://shopify/ProductVariant/46068180779263' },
    { car: 'Volkswagen', storeFrontId: 'gid://shopify/ProductVariant/46068180812031' }
]