import * as React from "react"

function Arrow(props) {
    return (
        <svg className='rotate-90 md:rotate-0' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18L15 12L9 6" stroke="#FF8B6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default Arrow
