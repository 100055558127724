import * as React from "react"

function Shield(props) {
    return (
        <svg className="mt-1" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.30201 20.6164C8.5234 20.7456 8.6341 20.8101 8.79032 20.8436C8.91156 20.8696 9.08844 20.8696 9.20968 20.8436C9.3659 20.8101 9.4766 20.7456 9.69799 20.6164C11.646 19.4799 17 15.91 17 11.0015V7.20153C17 6.12764 17 5.59069 16.8345 5.20953C16.6662 4.82213 16.4986 4.61605 16.1536 4.37244C15.8141 4.13276 15.1486 3.99436 13.8177 3.71755C12.3508 3.41247 11.2243 2.86157 10.1944 2.06485C9.70051 1.6828 9.45357 1.49177 9.26034 1.43967C9.05644 1.38469 8.94356 1.38469 8.73966 1.43967C8.54643 1.49177 8.29949 1.6828 7.80562 2.06485C6.77572 2.86157 5.6492 3.41247 4.1823 3.71755C2.85137 3.99436 2.18591 4.13276 1.84645 4.37244C1.50142 4.61605 1.33379 4.82213 1.16554 5.20953C1 5.59069 1 6.12764 1 7.20153V11.0015C1 15.91 6.35396 19.4799 8.30201 20.6164Z" stroke="#7C91B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
export default Shield
