import React from 'react'
import { Link } from "gatsby"

export default function Breadcrumb({ links }) {
    return (
        <ol className="flex items-center overflow-ellipsis space-x-1 truncate sm:ml-2 lg:ml-6 pt-1">
            {links.map((link, l) => (
                <li key={link.label}>
                    <div className="flex items-center">
                        {l > 0 ?
                            // <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                            //     <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            // </svg>

                            <svg className="flex-shrink-0 h-2 w-2 text-gray-300 pl-1" width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L3.85355 3.14645C4.04882 3.34171 4.04882 3.65829 3.85355 3.85355L0.853553 6.85355C0.658291 7.04882 0.341709 7.04882 0.146447 6.85355C-0.0488155 6.65829 -0.0488155 6.34171 0.146447 6.14645L2.79289 3.5L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z" fill="black" />
                            </svg>

                            : null}
                        <Link to={link.to} title={link.label} alt={link.label} aria-current={links.length - 1 === l ? 'page' : null} className={`text-xs md:text-sm font-normal text-gray-500 hover:text-lndred transition duration-150 ease-in-out ${l > 0 ? 'ml-2' : ''}`}>{link.label}</Link>
                    </div>
                </li>
            ))}
        </ol>
    )
}