import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'
import ZoomIcon from '../icons/zoom'
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "../styles/product-image-carousel.css"

export class ProductCarousel extends Component {
    constructor(props) {
        super();
        this.state = {
            images: props.images,
            current: ''
        }

        this.allProductImages = []
        if (props.images) {
            props.images.forEach((image) => {
                this.allProductImages.push({ original: image.originalSrc, thumbnail: image.originalSrc, originalTitle: props.title, originalAlt: "Post Collision " + props.title })
            })
        }
    }

    handleClickImage(e, image) {
        e && e.preventDefault()

        this.setState({
            current: image
        })
    }

    handleCloseModal = e => {
        e && e.preventDefault()

        this.setState({
            current: ''
        })
    }

    render() {
        var { images, current } = this.state
        var imagesToShow = this.allProductImages
        if (this.props.isSelected) {
            var selectedImages = GetImages(images, this.props.altText)

            if (selectedImages.length > 0) {
                imagesToShow = selectedImages
            }
        }

        return (
            <div className='container'>
                <div id="productCarousel" className="mx-auto px-12 md:px-10 max-w-md md:max-w-full md:w-full lg:px-0 lg:max-w-lg xl:max-w-xl 2xl:max-w-6xl 2xl:ml-10">
                    <ImageGallery items={imagesToShow}
                        ref={i => this._imageGallery = i}
                        showThumbnails={imagesToShow.length > 1}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        useBrowserFullscreen={false}
                        lazyLoad={true}
                        onClick={e => this.handleClickImage(e, imagesToShow[this._imageGallery.getCurrentIndex()])}
                    />

                    {current && (
                        <Lightbox mainSrc={current.original} onCloseRequest={this.handleCloseModal} />
                    )}

                    <div className="pt-2">
                        <ZoomIcon />
                    </div>
                </div>
            </div>
        )
    }
}

function GetImages(images, altText) {
    var imagesSrc = []

    for (let i = 0; i < images.length; i++) {
        if (images[i].altText === altText) {
            imagesSrc.push({ original: images[i].originalSrc, thumbnail: images[i].originalSrc, originalTitle: images[i].altText + " - " + images[i].product.title, originalAlt: images[i].altText + " - " + images[i].product.title })
        }
    }
    return imagesSrc
}