import React, { useState } from "react"
import Modal from "react-modal"
import { ShareSocial } from 'react-share-social'

export default function SharePopup(props) {
    const [isOpen, setIsOpen] = useState(false)

    function toggleModal() {
        setIsOpen(!isOpen)
    }

    const style = {
        content: {
            border: '1px solid #ccc',
            background: '#F3F6FB',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '0px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-40%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '370px',
            maxWidth:'400px',
            height: '100%'
        },
        overlay: {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1000
        }
    }

    const shareStyle = {
        copyContainer: {
          border: '1px solid #FF5A30',
          background: 'rgb(0,0,0,0.7)',
          Text:'#FF5A30'
        },
        copyIcon: {
            color:'#FF5A30'
        }
      }

    return (
        <div className="container lg:pb-5">
            <button
                className="text-gray-400 hover:text-gray-300 underline transition duration-150 ease-in-out translate-x-[140px] translate-y-[-40px] md:translate-x-[310px] lg:translate-x-[440px] absolute"
                type="button"
                title="Information On Shipping & Returns"
                alt="Information On Shipping & Returns"
                onClick={toggleModal}
            >

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="42" height="42">
                    <circle cx="12" cy="12" r="9" fill="#FF5A30" />
                    <svg width="8" height="8" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" x="8" y="8">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.50033 5.83329C2.85599 5.83329 2.33366 6.35563 2.33366 6.99996C2.33366 7.64429 2.85599 8.16663 3.50033 8.16663C4.14466 8.16663 4.66699 7.64429 4.66699 6.99996C4.66699 6.35563 4.14466 5.83329 3.50033 5.83329ZM1.16699 6.99996C1.16699 5.7113 2.21166 4.66663 3.50033 4.66663C4.78899 4.66663 5.83366 5.7113 5.83366 6.99996C5.83366 8.28862 4.78899 9.33329 3.50033 9.33329C2.21166 9.33329 1.16699 8.28862 1.16699 6.99996Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5003 2.33329C9.85599 2.33329 9.33366 2.85563 9.33366 3.49996C9.33366 4.14429 9.85599 4.66663 10.5003 4.66663C11.1447 4.66663 11.667 4.14429 11.667 3.49996C11.667 2.85563 11.1447 2.33329 10.5003 2.33329ZM8.16699 3.49996C8.16699 2.2113 9.21166 1.16663 10.5003 1.16663C11.789 1.16663 12.8337 2.2113 12.8337 3.49996C12.8337 4.78862 11.789 5.83329 10.5003 5.83329C9.21166 5.83329 8.16699 4.78862 8.16699 3.49996Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5003 9.33329C9.85599 9.33329 9.33366 9.85563 9.33366 10.5C9.33366 11.1443 9.85599 11.6666 10.5003 11.6666C11.1447 11.6666 11.667 11.1443 11.667 10.5C11.667 9.85563 11.1447 9.33329 10.5003 9.33329ZM8.16699 10.5C8.16699 9.2113 9.21166 8.16663 10.5003 8.16663C11.789 8.16663 12.8337 9.2113 12.8337 10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333C9.21166 12.8333 8.16699 11.7886 8.16699 10.5Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.4439 3.99116C9.59143 4.27756 9.47887 4.62933 9.19247 4.77687L5.34247 6.7602C5.05607 6.90774 4.7043 6.79517 4.55676 6.50878C4.40922 6.22238 4.52179 5.8706 4.80819 5.72307L8.65819 3.73973C8.94458 3.5922 9.29636 3.70476 9.4439 3.99116Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.55676 7.49116C4.7043 7.20476 5.05607 7.0922 5.34247 7.23973L9.19247 9.22307C9.47887 9.37061 9.59143 9.72238 9.4439 10.0088C9.29636 10.2952 8.94458 10.4077 8.65819 10.2602L4.80819 8.27687C4.52179 8.12933 4.40922 7.77756 4.55676 7.49116Z" fill="white" />
                    </svg>
                </svg>




            </button>
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    style={style}
                >
                    {isOpen ? (
                        <div className="text-center max-w-3xl mx-auto">
                            <div className="p-5">
                                {/*header*/}
                                <div className="pb-5 border-b border-solid border-blueGray-200">
                                    <p className="text-2xl font-semibold mx-auto">
                                        Share
                                    </p>
                                </div>
                                <div className="fixed top-5 right-5">
                                    <button className="text-lg lg:text-2xl font-bold hover:text-lndred transition duration-150 ease-in-out" title="Close Pop-up" alt="Close Pop-up" onClick={toggleModal}>X</button>
                                </div>
                                {/*body*/}
                                <div>
                                    <ShareSocial
                                        url={props.url}
                                        socialTypes={['facebook', 'twitter', 'linkedin', 'email', 'whatsapp']}
                                        style={shareStyle}
                                    />
                                </div>
                                {/*footer*/}
                                <div className="border-t border-solid border-blueGray-200 pt-5">
                                    <div className="">
                                        <button
                                            className="relative h-10 py-2 px-8 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out"
                                            type="button"
                                            title="Close Pop-up"
                                            alt="Close Pop-up"
                                            onClick={() => toggleModal()}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Modal>
            </div>
        </div>
    )
}