import * as React from "react"
import { StoreContext } from "../context/store-context"

export function AddToCart({ variantId, quantity, IsSelected, hasVariants, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <div>
      {props.isSeatBeltColor ?
        <button
          type="submit"
          className="font-semibold m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred disabled:bg-transparent text-white disabled:text-lndred hover:shadow-lg disabled:shadow-none hover:shadow-lndred transition duration-150 ease-in-out text-xs md:text-base"
          onClick={addToCart}
          disabled={(!IsSelected && hasVariants) || loading}
          {...props}
          title="Place Your Repair Order"
          alt="Place Your Repair Order - L&D Solutions"
        >
          {IsSelected ? "Place Your Repair Order" : "Select Option"}
        </button>
        :
        <button
          type="submit"
          className="font-semibold m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred disabled:bg-transparent text-white disabled:text-lndred hover:shadow-lg disabled:shadow-none hover:shadow-lndred transition duration-150 ease-in-out text-xs md:text-base"
          onClick={addToCart}
          disabled={loading}
          {...props}
          title="Place Your Repair Order"
          alt="Place Your Repair Order - L&D Solutions"
        >
          Place Your Repair Order
        </button>
      }
    </div>
  )
}
