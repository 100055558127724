import * as React from "react"

function Chevron(props) {
    return (
        <svg className='mt-0 xl:mt-0.5' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.52859 5.52858C3.78894 5.26823 4.21105 5.26823 4.4714 5.52858L8 9.05717L11.5286 5.52858C11.7889 5.26823 12.2111 5.26823 12.4714 5.52858C12.7317 5.78892 12.7317 6.21103 12.4714 6.47138L8.4714 10.4714C8.21105 10.7317 7.78894 10.7317 7.52859 10.4714L3.52859 6.47138C3.26824 6.21103 3.26824 5.78892 3.52859 5.52858Z" fill="#9CA3AF" />
        </svg>
    )
}
export default Chevron
